import _ from "lodash";
import React, { useEffect } from "react";
import queryString from "query-string";
import { Redirect } from "react-router-dom";
import Cookies from "js-cookie";
import { useMutation } from "@apollo/client";

import Layout from "../../components/layout/Layout";
import AuthQueries from "../../queries/auth";
import ProfileList from "../../queries/profile/ProfileList";
import withRoutes from "../withRoutes";
import { clearClient } from "../../client";

import SlackRedirect from "./SlackRedirect";

import LoginPage from "./LoginPage";
import LocationBlock from "./LocationBlock";

import RequestResetPasswordPage from "./RequestResetPasswordPage";
import ResetPasswordPage from "./ResetPasswordPage";

import RequestChangeEmailPage from "./RequestChangeEmailPage";
import ChangeEmailForm from "./ChangeEmailForm";

import SignupPage from "./SignupPage";

const TokenPage = ({ location, match, history }) => {
  const { origin } = queryString.parse(location?.search);
  const redirect = origin?.length ? origin : "/";

  const [magicLogin] = useMutation(AuthQueries.mutations.MagicLogin, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: ProfileList.MyProfileQuery
      }
    ],
    update: (cache, context) => {
      Cookies.set("token", context?.data?.magicLogin, { expires: 365 });
    },
    onCompleted: () => history.push(redirect)
  });

  useEffect(() => {
    const logged = Cookies.get("token");

    if (logged) {
      history.push(redirect);
    } else {
      if (match?.params?.token) {
        magicLogin({
          variables: {
            token: match?.params?.token
          }
        });
      }
    }
  });

  return <Redirect to={redirect} />;
};

export default withRoutes(Layout, [
  {
    path: "/add_to_slack",
    component: SlackRedirect
  },
  {
    path: "/linkedin/:token",
    component: ({ match }) => {
      let token = match.params.token;
      Cookies.set("token", token, { expires: 365 });

      return <Redirect to="/settings" />;
    }
  },
  {
    path: "/waitlist",
    component: LocationBlock,
    hideNav: true
  },
  {
    path: "/signup",
    component: SignupPage
  },
  {
    path: "/login",
    reviewRoute: true,
    component: LoginPage,
    state: "Login"
  },
  {
    path: "/logout",
    reviewRoute: true,
    component: () => {
      Cookies.remove("token");
      clearClient();
      return <Redirect to="/" />;
    }
  },
  {
    path: "/request_reset_password",
    component: RequestResetPasswordPage
  },
  {
    path: "/reset_password/:token",
    component: ResetPasswordPage
  },
  {
    path: "/request-change-email",
    component: RequestChangeEmailPage
  },
  {
    path: "/change-email",
    component: ChangeEmailForm
  },
  {
    path: `/auth/google/signup`,
    component: ({ location: { search } }) => {
      const { token, origin } = queryString.parse(search, { arrayFormat: "comma" });
      Cookies.set("token", token, { expires: 365 });

      const redirectPath = new URL(origin).searchParams.get("origin")

      let redirect = "/candidate/setup";
      if (redirectPath?.length > 0) {
        redirect = redirectPath;
      }

      return <Redirect to={redirect} />;
    }
  },
  {
    path: `/auth/google/login`,
    component: ({ location: { search } }) => {
      const { token, origin } = queryString.parse(search, { arrayFormat: "comma" });
      Cookies.set("token", token, { expires: 365 });

      const redirectPath = new URL(origin).searchParams.get("origin")

      let redirect = "/conversations";
      if (redirectPath?.length > 0) {
        redirect = redirectPath;
      }

      return <Redirect to={redirect} />;
    }
  },
  {
    path: `/auth/magic/:token`,
    component: TokenPage
  },
  {
    path: `/auth/zoom`,
    component: ({ location }) => {
      const { updatedLink } = queryString.parse(location.search);

      if (window && window.opener) {
        // Let the opener window know that zoom is connected
        window.opener.postMessage({
          zoomConnected: true,
          updatedLink
        });

        // Close the pop up window
        window.close();

        return null;
      } else return <Redirect to={`/`} />;
    }
  }
]);
