import React from "react";
import { Pane, Avatar, Heading, defaultTheme, majorScale, Text } from "evergreen-ui";

const ConnectionCardHeader = (props) => {
  return (
    <Pane display="flex" gap={majorScale(2)} alignItems="center">
      <Avatar src={props.recruiter.user.picture} size={majorScale(8)} />

      <Pane display="flex" flexDirection="column" gap={majorScale(1)}>
        <Heading size={400}>{props.recruiter.user.fullName}</Heading>

        {props.recruiter.user.role ? (
          <Text
            size={props.connectedAt ? 300 : 400}
            color={defaultTheme.colors.gray900}
          >{`${props.recruiter.user.role.title} at ${props.recruiter.user.role.team.name}`}</Text>
        ) : undefined}

        {props.connectedAt ? (
          <Text size={300}>
            {`CONNECTED ${new Date(parseInt(props.connectedAt, 10)).toLocaleDateString("en-US", { month: "long", day: "numeric", year: "numeric" })}`.toUpperCase()}
          </Text>
        ) : undefined}
      </Pane>
    </Pane>
  );
};

export default ConnectionCardHeader;
