import _ from "lodash";
import React from "react";
import { useMutation, useQuery } from "@apollo/client";
import { withRouter, Link as BrowserLink } from "react-router-dom";
import queryString from "query-string";
import { Hidden, Visible } from "react-grid-system";
import Cookies from "js-cookie";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { Alert, Button, Heading, Pane, majorScale, minorScale, defaultTheme, Text } from "evergreen-ui";

import withAuthLayout from "./withAuthLayout";
import ProfileList from "../../queries/profile/ProfileList";
import TextInputField from "../../components/form/TextInputField";

const ACCOUNT_EXISTS_MESSAGE = "Looks like there's already an account with that email address! Please log in below.";

const googleBtn = "https://ucarecdn.com/54a2f7b2-e855-4ae1-803b-0e43eb8dd17a/btn_google_signin_light_normal_web2x.png";

const LoginSchema = Yup.object().shape({
  email: Yup.string().email("Please provide a valid email").required("Please include your email"),
  password: Yup.string()
    .min(2, "Please include a password")
    .max(50, "Please shorten the password you use")
    .required("Please include a password")
});

const GoogleAuthButton = ({ screenClass }) => {
  // Show the google button immediately even though there may be
  // a slight delay before the URL renders properly
  const { data: googleAuthResult, loading, error } = useQuery(ProfileList.GoogleAuthLink, { 
    variables: { path: window.location.href }
  });
  const width = screenClass === "xs" ? "100%" : majorScale(24);

  return (
    <>
      {(loading || error) && <img src={googleBtn} width={width} marginTop={majorScale(2)} marginBottom={majorScale(2)} alt="Merit" />}
      {googleAuthResult && !(loading || error) && (
        <a href={googleAuthResult?.googleAuthLink}>
          <img src={googleBtn} width={width} alt="google" />
        </a>
      )}
    </>
  );
};

const LoginPage = ({ history, location, ...props }) => {
  const { accountExists } = queryString.parse(location.search);

  const [loginUser, { error }] = useMutation(ProfileList.LoginUser, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: ProfileList.MyProfileQuery
      }
    ],
    onCompleted: (result) => {
      const { origin } = queryString.parse(location.search);

      let redirect;

      if (origin && origin?.length > 0) {
        redirect = origin;
      } else if (result?.loginUser?.mode?.includes("Recruit")) {
        redirect = "/candidates/all";
      } else if (result?.loginUser?.coach?.id?.length > 0) {
        const status = result?.loginUser?.coach?.status;

        redirect = _.isEqual(status, "Active") || _.isEqual(status, "Inactive") || _.isEqual(status, "Pending") ? "/dashboard" : "discover";
      } else {
        redirect = "/discover";
      }

      history.push(redirect);
    },
    update: (cache, { data: { loginUser } }) => {
      const { jwt } = loginUser;

      Cookies.set("token", jwt, { expires: 365 });
    }
  });

  return (
    <Formik
      validationSchema={LoginSchema}
      initialValues={{ email: "", password: "" }}
      onSubmit={({ email, password }, { setSubmitting }) => {
        loginUser({ variables: { email, password } });
        setSubmitting(false);
      }}
      component={({ isSubmitting }) => {
        return (
          <Form>
            <Pane
              backgroundColor={"white"}
              borderRadius={minorScale(3)}
              border={`1px solid ${defaultTheme.colors.gray200}`}
              padding={minorScale(9)}
            >
              {accountExists && _.isEqual(accountExists, "true") && (
                <Alert margin={majorScale(2)} intent="info" title={ACCOUNT_EXISTS_MESSAGE} />
              )}
              <Pane display="flex" flexDirection="column" gap={minorScale(9)} justifyContent="center">
                <Pane display="flex" flexDirection="column">
                  <Heading size={900} marginBottom={minorScale(3)}>
                    Welcome back! 👋🏾
                  </Heading>

                  <Heading size={400} color={defaultTheme.colors.gray700}>
                    Log in below.
                  </Heading>
                </Pane>

                <Pane
                  display="flex"
                  alignItems="center"
                  paddingBottom={minorScale(9)}
                  borderBottom={`1px solid ${defaultTheme.colors.gray300}`}
                >
                  <GoogleAuthButton screenClass={props.screenClass} />
                </Pane>

                <Field type="text" name="email" placeholder="Enter your email address" label="Email" component={TextInputField} />

                <Field type="password" name="password" placeholder="Enter your password" label="Password" component={TextInputField} />

                {error &&
                  error.graphQLErrors.map(({ message }, i) => <Alert margin={majorScale(2)} intent="danger" title={message} key={i} />)}

                <Visible xs sm md>
                  <Pane>
                    <Button width="100%" type="submit" appearance="primary" textAlign="center" disabled={isSubmitting}>
                      Login
                    </Button>
                  </Pane>
                </Visible>

                <Pane display="flex" justifyContent="space-between" alignItems="center">
                  <Pane display="flex">
                    <Button
                      is={BrowserLink}
                      to="/request_reset_password"
                      appearance="minimal"
                      size="small"
                      color={defaultTheme.colors.blue400}
                    >
                      Reset Password
                    </Button>

                    <Text>&#x2022;</Text>

                    <Button is={BrowserLink} to="/signup" appearance="minimal" size="small" color={defaultTheme.colors.blue400}>
                      Create account
                    </Button>
                  </Pane>

                  <Hidden xs sm md>
                    <Pane>
                      <Button type="submit" appearance="primary" textAlign="center" disabled={isSubmitting}>
                        Login
                      </Button>
                    </Pane>
                  </Hidden>
                </Pane>
              </Pane>
            </Pane>
          </Form>
        );
      }}
    />
  );
};

export default withRouter(withAuthLayout(LoginPage));
