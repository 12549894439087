import { Pane, PeopleIcon, DocumentShareIcon, Heading, majorScale, minorScale, defaultTheme, Text } from "evergreen-ui";
import React from "react";
import LocationPane from "./LocationPane";

const JobLink = (props) => {
  return (
    <a href={props.job.link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
      <Pane
        display="flex"
        justifyContent="space-between"
        gap={majorScale(2)}
        backgroundColor={defaultTheme.colors.gray300}
        padding={majorScale(3)}
        borderRadius={majorScale(1)}
      >
        <Pane display="flex" flexDirection="column" gap={majorScale(2)}>
          <Heading>{props.job.title}</Heading>

          <Pane display="flex" gap={majorScale(2)}>
            <LocationPane location={props.job.location} />

            {props.job.team.teamSize ? (
              <Pane display="flex" alignItems="center" gap={minorScale(1)}>
                <PeopleIcon color={defaultTheme.colors.gray600} />

                <Text>{props.job.team.teamSize}</Text>
              </Pane>
            ) : undefined}
          </Pane>
        </Pane>

        <DocumentShareIcon color={defaultTheme.colors.gray600} />
      </Pane>
    </a>
  );
};

export default JobLink;
