import gql from "graphql-tag";

import ProfileList from "../profile/ProfileList";

const ApplicationQueries = {
  fragments: {},
  queries: {},
  mutations: {}
};

ApplicationQueries.queries.MyProfile = gql`
  query MyProfile {
    availableMotivations,
    myCoachingProfile {
      ${ProfileList.fragments.coach}
    },
    myProfile {
      ${ProfileList.fragments.profile},
      coach {
        id,
        reason,
        motivations
      },
      place {
        ${ProfileList.fragments.place}
      }
    },
  }
`;

ApplicationQueries.queries.BookMockSession = gql`
  query BookMockSession {
    myProfile {
      id
      fullName
      email
    }
    mockSession {
      id
      status
      date
      type
    }
  }
`;

ApplicationQueries.queries.ActivateCoachProfile = gql`
  query ActivateCoachProfile {
    myProfile {
      ${ProfileList.fragments.profile}
    },
    myCoachingProfile {
      ${ProfileList.fragments.coach}
    },
    mockSession {
      id,
      status,
      date,
      type
    }
  }
`;

ApplicationQueries.queries.MockTopics = gql`
  query MockTopics {
    facilitators {
      ${ProfileList.fragments.coach},
      onboardingTopic,
      user {
        ${ProfileList.fragments.profile}
      }
    },
    mockTopics {
      id,
      name,
      description
    },
  }
`;

ApplicationQueries.mutations.NewApplication = gql`
  mutation NewApplication(
    $website: String!
    $level: String!
    $reason: String!
    $jobRoles: [String]
    $gender: String
    $ethnicities: [String]
    $motivations: [String]
  ) {
    coachApplication(
      website: $website
      level: $level
      reason: $reason
      jobRoles: $jobRoles
      gender: $gender
      ethnicities: $ethnicities
      motivations: $motivations
    ) {
      status
    }
  }
`;

ApplicationQueries.mutations.CreateApplication = gql`
  mutation CreateApplication($gender: String, $ethnicities: [String], $pronouns: String) {
    createApplication(gender: $gender, ethnicities: $ethnicities, pronouns: $pronouns) {
      status
    }
  }
`;

ApplicationQueries.mutations.ExpandApplication = gql`
  mutation ExpandApplication($jobRoles: [String]!, $level: String!, $motivations: [String]!) {
    expandApplication(jobRoles: $jobRoles, level: $level, motivations: $motivations) {
      status
    }
  }
`;

ApplicationQueries.mutations.SubmitApplication = gql`
  mutation SubmitApplication {
    submitApplication {
      status
    }
  }
`;

ApplicationQueries.mutations.FinishCalendarSetup = gql`
  mutation FinishCalendarSetup {
    finishCalendarSetup {
      ${ProfileList.fragments.coach}
    }
  }
`;

ApplicationQueries.mutations.ReadBestPractices = gql`
  mutation ReadBestPractices {
    readBestPractices {
      ${ProfileList.fragments.coach}
    }
  }
`;

export default ApplicationQueries;
