import { Button, majorScale, Pane, ArchiveIcon, defaultTheme } from "evergreen-ui";
import React from "react";
import { useMutation } from "@apollo/client";
import { AsyncScreenQueries } from "../../queries/async-screen";
import ConnectionCardHeader from "./ConnectionCardHeader";

const ArchivedConnectionCard = (props) => {
  const [acceptCommonApplicationConnection] = useMutation(AsyncScreenQueries.mutations.AcceptCommonApplicationConnection, {
    variables: {
      connectionId: props.connectionId
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: AsyncScreenQueries.queries.GetDashboard,
        variables: {
          cursor: "1"
        }
      }
    ]
  });

  return (
    <Pane
      padding={majorScale(3)}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      gap={majorScale(3)}
      backgroundColor={defaultTheme.colors.white}
      borderRadius={majorScale(1)}
      border={`1px solid ${defaultTheme.colors.gray500}`}
    >
      <ConnectionCardHeader recruiter={props.recruiter} connectedAt={props.connectedAt} />

      <Button onClick={acceptCommonApplicationConnection} iconBefore={<ArchiveIcon color={defaultTheme.colors.gray600} />}>
        Reactivate
      </Button>
    </Pane>
  );
};

export default ArchivedConnectionCard;
