import React, { useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import landing from "./routes/landing";
import auth from "./routes/auth";
import admin from "./routes/admin";
import profile from "./routes/profile";
import calls from "./routes/calls";
import playlist from "./routes/playlist";
import coach from "./routes/coach";
import conversation from "./routes/conversation";
import onboarding from "./routes/onboarding";
import connection from "./routes/connection";
import guru from "./routes/guru";
import team from "./routes/team";
import session from "./routes/session";
import settings from "./routes/settings";
import leader from "./routes/leader";
import topic from "./routes/topic";
import group from "./routes/group";
import feed from "./routes/feed";
import nomination from "./routes/nomination";
import discover from "./routes/discover";
import discussion from "./routes/discussion";
import mentors from "./routes/mentors";
import candidate from "./routes/candidate";
import asyncScreen from "./routes/async-screen";

import Layout from "./components/layout/Layout";
import PlaylistPage from "./routes/landing/playlist";

import "./App.css";

const App = () => (
  <Router>
    <Switch>
      {admin}
      {auth}
      {calls}
      {profile}
      {playlist}
      {coach}
      {conversation}
      {onboarding}
      {connection}
      {guru}
      {team}
      {session}
      {settings}
      {leader}
      {topic}
      {group}
      {feed}
      {nomination}
      {discover}
      {discussion}
      {mentors}
      {asyncScreen}
      {landing}
      {candidate}

      <Route
        component={() => (
          <Layout state="Get hired">
            <PlaylistPage />
          </Layout>
        )}
      />
    </Switch>
  </Router>
);

export default App;
