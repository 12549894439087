import gql from "graphql-tag";
import CoachList from "../../routes/coach/CoachList";
import ProfileList from "../../queries/profile/ProfileList";

export const AsyncScreenQueries = {
  fragments: {},
  queries: {},
  mutations: {}
};

AsyncScreenQueries.fragments.Question = `
  id
  order
  title
  text
  prompts
  additionalInfo
`;

AsyncScreenQueries.fragments.Answer = `
  question {
    ${AsyncScreenQueries.fragments.Question} 
  }
  uri
  duration
`;

AsyncScreenQueries.fragments.Team = `
  logo
  name
  teamSize
`;

AsyncScreenQueries.fragments.Job = `
  id
  link
  location
  team {
    logo
    teamSize
  }
  title
  role
`;

AsyncScreenQueries.fragments.User = `
  id
  fullName
  picture
  location
  slug
  candidate {
    workStylePreference
    frameworks
    hasResume
    jobRole
    specialities {
      skill
    }
  }
  role {
    title
    currentRole
    team {
      logo
      name
    }
  }
`;

AsyncScreenQueries.fragments.CommonApplicationConnection = `
  id
  status
  message
  sentAt
  connectedAt
  recruiter {
    team {
      ${AsyncScreenQueries.fragments.Team}
    }
    user {
      ${AsyncScreenQueries.fragments.User}
    }
  }
  job {
    ${AsyncScreenQueries.fragments.Job}
  }
`;

AsyncScreenQueries.fragments.CommonApplication = `
  answers {
    ${AsyncScreenQueries.fragments.Answer}
  }
  user {
    ${AsyncScreenQueries.fragments.User}
  }
  canShare
  connections {
    ${AsyncScreenQueries.fragments.CommonApplicationConnection}
  }
`;

AsyncScreenQueries.queries.GetQuestions = gql`
  query GetQuestions {
    questions {
      ${AsyncScreenQueries.fragments.Question}
    }
  }
`;

AsyncScreenQueries.queries.GetAsyncScreenInfo = gql`
  query GetAsyncScreenInfo($title: String!) {
    questions {
      ${AsyncScreenQueries.fragments.Question}
    }
    answerUploadPresignedUrl(title: $title)
    commonApplication {
      ${AsyncScreenQueries.fragments.CommonApplication}
    }
  }
`;

AsyncScreenQueries.queries.GetCommonApplication = gql`
  query GetCommonApplication {
    commonApplication {
      ${AsyncScreenQueries.fragments.CommonApplication}
    }
  }
`;

AsyncScreenQueries.queries.GetDashboard = gql`
  query GetDashboard(${CoachList.LeaderSearchQueryOptions.vars}, $cursor: String) {
    commonApplication {
      ${AsyncScreenQueries.fragments.CommonApplication}
    }
    leaders(${CoachList.LeaderSearchQueryOptions.args}, cursor: $cursor) {
      edges {
        ${CoachList.fragments.mentor},
        totalSessions,
        totalReviews,
        user {
          ${ProfileList.fragments.profile},
          candidate {
            id,
            specialities {
              skill,
              jobRole,
              proficiency
            },
            resume {
              url
            }
          }
        }
      }
    }
  }
`;

AsyncScreenQueries.queries.GetJob = gql`
  query GetJob($jobId: String!) {
    jobById(jobId: $jobId) {
      id
      link
      location
      team {
        logo
        name
        teamSize
      }
      title
      role
    }
  }
`;

AsyncScreenQueries.mutations.CommonApplication = gql`
  mutation CommonApplication($questionId: String!, $pathname: String!, $duration: Int!) {
    putCommonApplicationWithAnswer(questionId: $questionId, pathname: $pathname, duration: $duration) {
      ${AsyncScreenQueries.fragments.CommonApplication}
    }
  }
`;

AsyncScreenQueries.mutations.ShareCommonApplication = gql`
  mutation ShareCommonApplication($canShare: Boolean!) {
    setShareCommonApplication(canShare: $canShare) {
      ${AsyncScreenQueries.fragments.CommonApplication}
    }
  }
`;

AsyncScreenQueries.mutations.AcceptCommonApplicationConnection = gql`
  mutation AcceptCommonApplicationConnection($connectionId: String!) {
    acceptCommonApplicationConnection(connectionId: $connectionId) {
      ${AsyncScreenQueries.fragments.CommonApplicationConnection}
    }
  }
`;

AsyncScreenQueries.mutations.ArchiveCommonApplicationConnection = gql`
  mutation ArchiveCommonApplicationConnection($connectionId: String!) {
    archiveCommonApplicationConnection(connectionId: $connectionId) {
      ${AsyncScreenQueries.fragments.CommonApplicationConnection}
    }
  }
`;
