import _ from "lodash";
import React from "react";
import injectSheet from "react-jss";
import { Row, Col, Container, Hidden, Visible } from "react-grid-system";
import { Link as BrowserLink } from "react-router-dom";
import { Pane, TabNavigation, Tab, minorScale, majorScale, defaultTheme, Button } from "evergreen-ui";
import { slide as Menu } from "react-burger-menu";

import NavItem from "./NavItem";
import Link from "../Link";
import theme from "../theme";
import { logo } from "../../content/about";

const SIGNUP_TEXT = "Sign Up";

const styles = {
  tab: {
    color: theme.color.text,
    fontFamily: "'Karla', sans-serif",
    fontstyle: "normal",
    fontSize: "16px",
    lineHeight: "20px",
    textAlign: "center",
    borderRadius: 0,
    paddingLeft: 0,
    paddingRight: 0,
    marginLeft: "16px",
    marginRight: "16px",
    backgroundColor: "transparent"
  },
  tabStyle: {
    fontWeight: 400,
    "&:hover": {
      all: "initial"
    }
  },
  selectedStyle: {
    fontWeight: 700,
    borderBottom: `2px ${theme.color.brand} solid`
  },
  buttonStyle: {
    backgroundColor: theme.color.brand,
    color: theme.color.whiteText,
    fontWeight: 700
  },
  link: {
    color: theme.color.whiteText,
    textDecoration: "none"
  },
  h3: {
    ...theme.h3,
    margin: 0,
    color: theme.color.whiteText,
    paddingBottom: theme.spacing.grid
  },
  loginButtonOnMobile: {
    border: `1px solid ${defaultTheme.colors.gray500}`,
    borderRadius: ".5rem",
    display: "block",
    textAlign: "center",
    padding: ".625rem 1.125rem",
    color: defaultTheme.colors.gray800,
    textDecoration: "none",
    fontWeight: 600,
    fontSize: "1rem",
    fontFamily: "DM Sans, sans-serif"
  }
};

// Need to explicitly define styles for the burger menu
const burgerStyles = {
  bmBurgerButton: {
    position: "absolute",
    width: theme.spacing.grid * 1.5,
    height: "20px",
    top: "10px",
    right: theme.spacing.grid
  },
  bmBurgerBars: {
    background: "#344054",
    height: "1px"
  },
  bmCrossButton: {
    height: theme.spacing.grid,
    width: theme.spacing.grid,
    top: "20px",
    right: "20px"
  },
  bmCross: {
    background: "#344054",
    width: "1px",
    height: "18px"
  },
  bmMenu: {
    backgroundColor: defaultTheme.colors.gray100,
    padding: 0
  },
  bmMenuWrap: {
    top: 0
  },
  bmItemList: {
    padding: theme.spacing.grid
  },
  bmItem: {
    display: "inline-block",
    color: "#292929"
  },
  bmOverlay: {
    background: "transparent"
  }
};

const stylePicker = (tab, buttonLabel, index, selectedIndex) => {
  if (_.isEqual(tab.title, buttonLabel)) {
    return { ...styles.tab, ...styles.buttonStyle };
  } else if (_.isEqual(index, selectedIndex)) {
    return { ...styles.tab, ...styles.selectedStyle };
  } else {
    return { ...styles.tab, ...styles.tabStyle };
  }
};

const MeritLogo = (props) => {
  const homeLink = props.logged ? "/conversations/person" : "/";

  return (
    <Pane display="flex" is="a" href={homeLink} paddingRight={majorScale(3)}>
      <img height={minorScale(9)} src={logo} alt="Merit" />
    </Pane>
  );
};

const Navigation = ({ items, location, logged, headerState }) => {
  let initialIndex = _.findIndex(items, { title: headerState });
  let selectedIndex = initialIndex;

  const rightTabs = 1;
  const centerTabs = _.size(items) - rightTabs;
  const tabsCenter = _.dropRight(items, rightTabs);
  const tabsRight = _.drop(items, centerTabs);

  return (
    <Container style={{ width: "100%", height: "80px", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Row style={{ width: "100%" }}>
        <Col xs={12} lg={9} style={{ display: "flex", alignItems: "center" }}>
          <MeritLogo logged={logged} />

          <Visible xs sm md>
            <Menu styles={burgerStyles} width="100%" right>
              <Col style={{ paddingBottom: majorScale(3), borderBottom: `1px solid ${defaultTheme.colors.gray200}` }}>
                <MeritLogo />
              </Col>

              {tabsCenter.map((item, indx) => {
                return (
                  <Col key={indx} style={{ paddingTop: majorScale(3) }}>
                    <NavItem mobile item={item} location={location} />
                  </Col>
                );
              })}

              {tabsRight.map((item, index) => {
                return (
                  <Col key={index} style={{ paddingTop: majorScale(3) }}>
                    <a href={item.link} style={styles.loginButtonOnMobile}>
                      {item.title}
                    </a>
                  </Col>
                );
              })}
            </Menu>
          </Visible>

          <Hidden xs sm md>
            <TabNavigation>
              {tabsCenter.map((tab, index) => (
                <Tab
                  key={tab.title}
                  is="a"
                  href={tab.link}
                  style={stylePicker(tab, SIGNUP_TEXT, index, selectedIndex)}
                  d={tab.title}
                  onSelect={() => (selectedIndex = initialIndex)}
                  isSelected={index === selectedIndex}
                >
                  {tab.title}
                </Tab>
              ))}
            </TabNavigation>
          </Hidden>
        </Col>

        <Hidden xs sm md>
          <Col lg={3} style={{ display: "flex", justifyContent: "right", alignItems: "center" }}>
            <TabNavigation>
              {tabsRight.map((tab, index) => (
                <Tab
                  key={tab.title}
                  is={_.isEqual(tab.title, SIGNUP_TEXT) ? Link : BrowserLink}
                  to={tab.link}
                  style={stylePicker(tab, SIGNUP_TEXT, index + centerTabs, selectedIndex)}
                  d={tab.title}
                  onSelect={() => (selectedIndex = initialIndex)}
                  isSelected={index + centerTabs === selectedIndex}
                >
                  {tab.title}
                </Tab>
              ))}
            </TabNavigation>
          </Col>
        </Hidden>
      </Row>
    </Container>
  );
};

export default injectSheet(styles)(Navigation);
