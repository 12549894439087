import React from "react";
import { Pane, MapMarkerIcon, Text, defaultTheme, minorScale } from "evergreen-ui";

const LocationPane = (props) => {
  return (
    <Pane display="flex" alignItems="center" gap={minorScale(1)}>
      <MapMarkerIcon color={defaultTheme.colors.gray600} />

      <Text>{props.location}</Text>
    </Pane>
  );
};

export default LocationPane;
