import _ from "lodash";
import React from "react";
import { useQuery } from "@apollo/client";
import { Row, Col, ScreenClassRender, Hidden } from "react-grid-system";
import { Heading, Pane, Paragraph, majorScale, defaultTheme as theme } from "evergreen-ui";

import ApplicationQueries from "../../queries/application";
import Loader from "../../components/Loader";

import CoachCareerForm from "../../components/application/CoachCareerForm";
import CoachProfileForm from "../../components/application/CoachProfileForm";
import BackButton from "../../components/BackButton";

const CoachApplicationSteps = [{ component: CoachProfileForm }, { component: CoachCareerForm }];

const BOTH_USER_BADGE =
  "Please fill out the form below to apply to be a mentor as well. We'll use this information to create a mentor profile for you once accepted.";
const COACH_BADGE =
  "Please fill out the form below to apply to be a mentor. We'll use this information to create a mentor profile for you once accepted.";

const CoachApplicationPage = (props) => {
  const { data: applicationContext, loading } = useQuery(ApplicationQueries.queries.MyProfile);

  const currentStep = props?.match?.params?.step ? parseInt(props.match.params.step) : 0;
  const isBothUser = props?.location?.search?.includes("both");

  const CurrentStep = CoachApplicationSteps[currentStep]["component"];
  const isLastStep = _.isEqual(currentStep, CoachApplicationSteps.length - 1);

  let myProfile,
    availableMotivations,
    myCoachingProfile,
    availableGenders,
    availableEthnicities,
    availableRoles,
    availableLevels,
    availablePronouns;

  if (applicationContext) {
    ({ myProfile, availableMotivations, myCoachingProfile } = applicationContext);
    ({ availableGenders, availableEthnicities, availableRoles, availableLevels, availablePronouns } = myProfile);
  }

  const width = {
    xs: 375,
    // xs: 540,
    sm: 740,
    md: 960,
    xl: 1140,
    xxl: 1140
  };

  return (
    <ScreenClassRender
      render={(screenClass) => {
        const isMobile = screenClass?.includes("xs");

        return (
          <Row
            justify="flex-start"
            style={{
              backgroundColor: theme.colors.gray100,
              minHeight: "calc(100vh - 120px)",
              paddingY: majorScale(4),
              width: width[screenClass]
            }}
          >
            <Hidden xs>
              <Col sm={2} md={2} lg={2} xs={12}>
                <BackButton />
              </Col>
            </Hidden>
            <Col lg={8} md={8} sm={8} xs={12}>
              {loading && !applicationContext && <Loader />}
              {applicationContext && (
                <Pane paddingX={isMobile ? null : majorScale(4)} paddingBottom={majorScale(4)}>
                  <Pane
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                    marginTop={majorScale(4)}
                    marginBottom={isMobile ? majorScale(2) : majorScale(4)}
                  >
                    <Heading size={700} marginRight={majorScale(1)}>
                      📝 Mentor Application
                    </Heading>
                    <Heading size={700} marginRight={majorScale(1)}>
                      {`${currentStep + 1} of ${CoachApplicationSteps.length}`}
                    </Heading>
                  </Pane>
                  <Pane
                    display="flex"
                    flexDirection="column"
                    width="100%"
                    padding={majorScale(4)}
                    borderRadius={majorScale(2)}
                    border={`1px solid ${theme.colors.gray500}`}
                    backgroundColor="white"
                    minHeight="100px"
                  >
                    {_.isEqual(currentStep, 0) ? (
                      <Paragraph size={500} marginBottom={majorScale(2)}>
                        {isBothUser ? BOTH_USER_BADGE : COACH_BADGE}
                      </Paragraph>
                    ) : null}
                    <CurrentStep
                      availableMotivations={availableMotivations}
                      availableGenders={availableGenders}
                      availableEthnicities={availableEthnicities}
                      availableRoles={availableRoles}
                      availableLevels={availableLevels}
                      availablePronouns={availablePronouns}
                      lastStep={isLastStep}
                      currentStep={currentStep}
                      myCoachingProfile={myCoachingProfile}
                      isMobile={isMobile}
                      handleNextStep={(result) => {
                        if (
                          myCoachingProfile &&
                          (_.isEqual(myCoachingProfile?.status, "Rejected") || _.isEqual(myCoachingProfile?.status, "Waitlist"))
                        ) {
                          props.history.push(`/application/${myCoachingProfile?.status?.toLowerCase()}`);
                        } else if (isLastStep) {
                          window.analytics.track("Completed Coach Application");

                          if (_.isEqual(myCoachingProfile?.status, "Accepted") || _.isEqual(myCoachingProfile?.status, "Active")) {
                            window.analytics.track("View Accepted Mentor Profile");
                            props.history.push(`/application/accepted`);
                          } else {
                            props.history.push(`/application/applicant`);
                          }
                        } else {
                          props.history.push(`/apply/${currentStep + 1}`);
                        }
                      }}
                      {...props}
                    />
                  </Pane>
                </Pane>
              )}
            </Col>
          </Row>
        );
      }}
    />
  );
};

export default CoachApplicationPage;
