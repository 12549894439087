import _ from "lodash";
import React from "react";
import { useMutation } from "@apollo/client";

import {
  Badge,
  Button,
  Heading,
  Pane,
  majorScale,
  Alert,
  Autocomplete as EvergreenAutoComplete,
  TextInput as TextInputControl
} from "evergreen-ui";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Select from "react-select";

import ApplicationQueries from "../../queries/application";

const GENDER_PLACEHOLDER = `Please identify your gender`;
const ETHNICITY_PLACEHOLDER = `Please identify your ethnicities`;
const PRONOUN_PLACEHOLDER = `Please identify your pronouns`;
const GENDER_ERROR = `Please add your gender.`;
const ETHNICITY_ERROR = `Please add your ethnicities.`;
const PRONOUN_ERROR = `Please add your pronouns.`;
const INFO_WARNING =
  "We collect this to ensure Merit is a representative community. We do not share individual information with any 3rd parties or publicly.";

const CoachProfileSchema = Yup.object().shape({
  ethnicities: Yup.array().of(Yup.string()),
  gender: Yup.string()
});

const createLabel = (label) => ({ value: label, label });

const Error = ({ text }) => (
  <Badge marginTop={majorScale(2)} color="red">
    {text}
  </Badge>
);

const CoachProfileForm = (formProps) => {
  const { availableGenders, availableEthnicities, availablePronouns, lastStep, handleNextStep } = formProps;

  const [createCoachApplication] = useMutation(ApplicationQueries.mutations.CreateApplication, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: ApplicationQueries.queries.MyProfile
      }
    ],
    onCompleted: (result) => {
      window.analytics.track("New Coach Application");
      handleNextStep(result);
    }
  });

  return (
    <Formik
      validationSchema={CoachProfileSchema}
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={{
        gender: null,
        ethnicities: [],
        pronouns: null
      }}
      onSubmit={(submitted, { setSubmitting }) => {
        const { website, gender, ethnicities, pronouns } = submitted;

        createCoachApplication({ variables: { website, gender, ethnicities, pronouns } });
        setSubmitting(false);
      }}
      component={({ values, errors, isSubmitting, setFieldValue }) => (
        <Form>
          {/* Demographics */}
          <Pane>
            <Heading size={600} paddingBottom={majorScale(2)} paddingTop={majorScale(2)}>
              What is your ethnicity and gender?
            </Heading>
            <Alert appearance="card" intent="none" marginBottom={majorScale(2)}>
              {INFO_WARNING}
            </Alert>

            {/* Ethnicity */}
            <Pane paddingTop={majorScale(2)} paddingBottom={majorScale(2)}>
              <Select
                isMulti
                defaultValue={values.ethnicities}
                options={availableEthnicities.map(createLabel)}
                placeholder={ETHNICITY_PLACEHOLDER}
                onChange={(selected) => {
                  const ethnicities = _.map(selected, (ethnicity) => ethnicity.value);
                  setFieldValue("ethnicities", ethnicities, false);
                }}
              />
            </Pane>
            {errors?.ethnicities && <Error text={ETHNICITY_ERROR} />}

            {availableGenders && (
              <EvergreenAutoComplete
                selectedItem={values.gender}
                allowOtherValues={true}
                onChange={(selected) => setFieldValue("gender", selected)}
                items={availableGenders}
              >
                {({ getInputProps, getRef, inputValue, openMenu }) => (
                  <Pane display="flex">
                    <TextInputControl
                      flex="1"
                      placeholder={GENDER_PLACEHOLDER}
                      value={inputValue}
                      ref={getRef}
                      {...getInputProps({
                        onFocus: () => {
                          openMenu();
                        }
                      })}
                    />
                  </Pane>
                )}
              </EvergreenAutoComplete>
            )}
            {errors?.gender && <Error text={GENDER_ERROR} />}

            <Pane paddingTop={majorScale(2)}>
              {availablePronouns && (
                <EvergreenAutoComplete
                  selectedItem={values.pronouns}
                  allowOtherValues={true}
                  onChange={(selected) => setFieldValue("pronouns", selected)}
                  items={availablePronouns}
                >
                  {({ getInputProps, getRef, inputValue, openMenu }) => (
                    <Pane display="flex">
                      <TextInputControl
                        flex="1"
                        placeholder={PRONOUN_PLACEHOLDER}
                        value={inputValue}
                        ref={getRef}
                        {...getInputProps({
                          onFocus: () => {
                            openMenu();
                          }
                        })}
                      />
                    </Pane>
                  )}
                </EvergreenAutoComplete>
              )}
            </Pane>
            {errors?.pronouns && <Error text={PRONOUN_ERROR} />}
          </Pane>

          <Pane display="flex" justifyContent="flex-end" paddingTop={majorScale(2)}>
            <Button appearance="primary" type="submit" disabled={isSubmitting}>
              {lastStep ? "Submit Application" : "Next Step"}
            </Button>
          </Pane>
        </Form>
      )}
    />
  );
};

export default CoachProfileForm;
