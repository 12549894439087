import gql from "graphql-tag";

const ProfileList = {
  fragments: {}
};

ProfileList.fragments.place = `
  id,
  formatted
  city
  region
  country
  lat
  long
  placeId
`;

ProfileList.fragments.profileLite = `
  id,
  fullName,
  linkedin,
  twitter,
  pronouns,
  picture,
  slug,
  isAdmin,
  coach {
    id
  },
  jobRoles,
  level,
  role {
    id,
    title,
    startDate,
    endDate,
    team {
      id,
      name,
      logo,
      website
    }
  }
`;

ProfileList.fragments.profile = `
  id,
  slug,
  isAdmin,
  isFTU,
  fullName,
  slug,
  name {
    first,
    last
  },
  pronouns,
  plan,
  isOnboarded,
  linkedin,
  twitter,
  website,
  bio,
  picture,
  cover,
  jobRole,
  jobRoles,
  level,
  mode,
  coachingPlaylists {
    id,
    name,
    motivations,
  },
  place {
    ${ProfileList.fragments.place}
  },
  gender,
  ethnicities,
  pronouns,
  timezone,
  location,
  googleConnected,
  nylasConnected,
  bestPractices,
  availableGenders,
  availablePronouns,
  availableEthnicities,
  availableRoles,
  availableLevels,
  degrees {
    id,
    major,
    field,
    start,
    end,
    school {
      id,
      logo,
      name,
      website
    }
  },
  roles {
    id,
    title,
    startDate,
    endDate,
    companySize,
    companyIndustry,
    currentRole,
    description,
    team {
      id,
      name,
      logo,
      website
    }
  },
  isPaused {
    start,
    end
  },
  automatedFields,
  role {
    id,
    title,
    startDate,
    endDate,
    currentRole,
    companySize,
    companyIndustry,
    description,
    team {
      id,
      name,
      logo,
      website,
      industries {
        id,
        name,
        keyword
      }
    }
  }
`;

ProfileList.fragments.coach = `
  id,
  totalSessions,
  isAvailable,
  maxSessions,
  meetingLength,
  status,
  openEnded,
  approveSession,
  reason,
  motivations,
  pauseCoaching {
    start,
    end,
    reason
  },
  topics {
    id,
    name
  },
  playlists {
    id,
    name
  },
  topicsAvailable {
    id,
    name
  },
  createdAt,
  learnersHelped,
  bestPractices
`;

ProfileList.profileQuery = gql`
query ProfileQuery($profileID: String, $resetToken: String) {
  profile(profileID: $profileID, resetToken: $resetToken) {
    ${ProfileList.fragments.profile}
  }
}
`;

ProfileList.ProfileQuery = gql`
  query ProfileQuery($profileID: String!) {
    myProfile {
      ${ProfileList.fragments.profile},
      email
    },
    coachByProfileId(profileID: $profileID) {
      ${ProfileList.fragments.coach},
    },
    profile(profileID: $profileID) {
      ${ProfileList.fragments.profile}
    }
  }
`;

ProfileList.MentorDetail = gql`
  query MentorDetail($profileID: String!, $slug: String) {
    hasConversation(userID: $profileID, slug: $slug) {
      id
    },
    coachByProfileId(profileID: $profileID) {
      ${ProfileList.fragments.coach},
      nextAvailable {
        start,
        end
      }
    }
  }
`;

ProfileList.coachQuery = gql`
  query CoachDetailQuery($profileID: String!) {
    coachByProfileId(profileID: $profileID) {
      ${ProfileList.fragments.coach},
      user {
        ${ProfileList.fragments.profile}
      }
    }
  }
`;

ProfileList.adminQuery = gql`
  query AdminQuery {
    admins {
      ${ProfileList.fragments.coach},
      onboarding,
      user {
        ${ProfileList.fragments.profile}
      }
    }
  }
`;

ProfileList.coachesByTopic = gql`
  query CoachByTopicQuery($topicName: [String!]!) {
    coaches(topic: $topicName) {
      ${ProfileList.fragments.coach},
      user {
        ${ProfileList.fragments.profile}
      }
    },
    topics {
      id,
      name,
      description
    }
  }
`;

ProfileList.MyProfileQuery = gql`
  query getProfile {
    myProfile {
      ${ProfileList.fragments.profile},
      calendar,
      editToken,
      applicationStatus,
    },
  }
`;

ProfileList.LayoutQuery = gql`
  query LayoutQuery {
    myProfile {
      ${ProfileList.fragments.profile},
      calendar,
      editToken,
      applicationStatus,
      place {
        ${ProfileList.fragments.place}
      }
    },
    myCoachingProfile {
      ${ProfileList.fragments.coach},
    },
    myCandidateProfile {
      id,
      status,
    },
    missingRecommendations {
      id,
      connectedAt,
      sessionsCompleted,
      amItheMentor,
      conversationThread {
        id,
      }
      viewing {
        ${ProfileList.fragments.profile}
      },
      recommendation {
        id,
        question,
        response,
        status,
        createdAt,
        updatedAt,
        owner {
          ${ProfileList.fragments.profile}
        },
        user {
          ${ProfileList.fragments.profile}
        },
      }
    }
  }
`;

ProfileList.LoginUser = gql`
  mutation loginUser($email: String!, $password: String!) {
    loginUser(email: $email, password: $password) {
      jwt
      id
      coach {
        id
        status
      }
      name {
        first
        last
      }
      linkedin
      bio
      schedule
      isOnboarded
      mode
      role {
        id
        title
        team {
          id
          name
        }
      }
    }
  }
`;

ProfileList.MyCoachingProfile = gql`
  query MyCoachingProfile {
    myProfile {
      ${ProfileList.fragments.profile},
      calendar,
      editToken,
      email,
      groups {
        id,
        name,
        logo,
        description,
      },
      place {
        ${ProfileList.fragments.place}
      }
    },
    myCoachingProfile {
      ${ProfileList.fragments.coach},
      nextAvailable {
        start,
        end
      }
      minNotice,
      openingHours {
        days,
        start,
        end
      },
      isPaused {
        start,
        end
      }
    }
  }
`;

ProfileList.CreateAccountQuery = gql`
  query CreateAccountQuery($resetToken: String) {
    profile(resetToken: $resetToken) {
      ${ProfileList.fragments.profile}
    }
  }
`;

ProfileList.UpdateMaxSessions = gql`
  mutation UpdateMaxSessions($maxSessions: String) {
    updateMaxSessions(maxSessions: $maxSessions) {
      ${ProfileList.fragments.coach}
    }
  }
`;

ProfileList.UpdateMeetingLength = gql`
  mutation UpdateMeetingLength($meetingLength: Int) {
    updateMeetingLength(meetingLength: $meetingLength) {
      ${ProfileList.fragments.coach}
    }
  }
`;

ProfileList.UpdateStatus = gql`
  mutation UpdateStatus($status: String!, $reason: String, $tag: String) {
    updateStatus(status: $status, reason: $reason, tag: $tag) {
      ${ProfileList.fragments.coach}
    }
  }
`;

ProfileList.UpdatePauseDates = gql`
  mutation UpdatePauseDates($start: String, $end: String, $reason: String) {
    setPauseDates(start: $start, end: $end, reason: $reason) {
      ${ProfileList.fragments.coach}
    }
  }
`;

ProfileList.RemovePauseDates = gql`
  mutation RemovePauseDates {
    removePauseDates {
      ${ProfileList.fragments.coach}
    }
  }
`;

ProfileList.ExpandProfile = gql`
  mutation ExpandProfile(
    $roles: [String], $jobTitle: String, $mode: String,
    $companyName: String, $companyDomain: String, $companyLogo: String,
    $referral: String, $startDate: String, $endDate: String,
  ) {
    expandProfile (
      roles: $roles, jobTitle: $jobTitle, mode: $mode,
      companyName: $companyName, companyDomain: $companyDomain,
      companyLogo: $companyLogo, referral: $referral,
      startDate: $startDate, endDate: $endDate
    ) {
      ${ProfileList.fragments.profile}
    }
  }
`;

ProfileList.ShowHumanity = gql`
  mutation ShowHumanity($website: String, $name: String) {
    showHumanity(website: $website, name: $name) {
      ${ProfileList.fragments.profile},
      applicationStatus
    }
  }
`;

ProfileList.CustomizeProfile = gql`
  mutation CustomizeProfile(
    $jobRoles: [String], $motivations: [String], $careerGoal: String,
  ) {
    customizeProfile (
      jobRoles: $jobRoles, motivations: $motivations, careerGoal: $careerGoal
    ) {
      ${ProfileList.fragments.profile}
    }
  }
`;

ProfileList.InitialPost = gql`
  mutation InitialPost(
    $jobRoles: [String], $title: String, $details: String,
  ) {
    initialPost (
      jobRoles: $jobRoles, title: $title, details: $details
    ) {
      ${ProfileList.fragments.profile}
    }
  }
`;

ProfileList.UpdateTopics = gql`
  mutation UpdateTopics($topics: [String]) {
    updateTopics (topics: $topics) {
    ${ProfileList.fragments.coach}
  }
}
`;

ProfileList.UpdateEthnicities = gql`
  mutation UpdateEthnicities($ethnicities: [String]) {
    updateEthnicities (ethnicities: $ethnicities) {
    ${ProfileList.fragments.profile},
    calendar,
    editToken
  }
}
`;

ProfileList.UpdatePronouns = gql`
  mutation UpdatePronouns($pronouns: String) {
    updatePronouns (pronouns: $pronouns) {
    ${ProfileList.fragments.profile}
  }
}
`;

ProfileList.UpdateGender = gql`
  mutation UpdateGender($gender: String) {
    updateGender (gender: $gender) {
    ${ProfileList.fragments.profile},
    calendar,
    editToken
  }
}
`;

ProfileList.UpdateTimezone = gql`
  mutation UpdateTimezone($timezone: String) {
    updateTimezone (timezone: $timezone) {
    ${ProfileList.fragments.profile},
    calendar,
    editToken
  }
}
`;

ProfileList.UpdateLocation = gql`
  mutation updateLocation($location: String) {
    updateLocation (location: $location) {
    ${ProfileList.fragments.profile},
    calendar,
    editToken
  }
}
`;

ProfileList.UpdateJobRole = gql`
  mutation updateJobRole($jobRole: String) {
    updateJobRole (jobRole: $jobRole) {
      ${ProfileList.fragments.profile}
    }
  }
`;

ProfileList.UpdateLevel = gql`
  mutation updateLevel($level: String) {
    updateLevel (level: $level) {
      ${ProfileList.fragments.profile}
    }
  }
`;

ProfileList.UpdateProfilePhoto = gql`
  mutation updateProfile($picture: String) {
    updateProfile(picture: $picture) {
      id
      picture
    }
  }
`;

ProfileList.UpdateGoogleAccessToken = gql`
  mutation UpdateGoogleAccessToken($refreshToken: String, $accessToken: String, $id: String, $email: String) {
    updateGoogleAccessToken(refreshToken: $refreshToken, accessToken: $accessToken, id: $id, email: $email) {
      ${ProfileList.fragments.profile}
    }
  }
`;

ProfileList.RevokeGoogleAccessToken = gql`
  mutation RevokeGoogleAccessToken {
    revokeGoogleAccessToken {
      ${ProfileList.fragments.profile}
    }
  }
`;

ProfileList.GoogleConnectionQuery = gql`
  query GoogleConnectionQuery {
    googleConnectionQuery {
      email
    }
  }
`;

ProfileList.FreeBusyQuery = gql`
  query FreeBusyQuery($date: String) {
    freeBusy(date: $date)
  }
`;

ProfileList.UpdateVideoLink = gql`
  mutation UpdateVideoLink($videoLink: String) {
    updateVideoLink(videoLink: $videoLink)
  }
`;

ProfileList.UpdateCover = gql`
  mutation UpdateCover($image: String!) {
    updateCover(image: $image)
  }
`;

ProfileList.DisconnectZoom = gql`
  mutation DisconnectZoom {
    disconnectZoom
  }
`;

ProfileList.VideoLinkQuery = gql`
  query VideoLinkQuery {
    videoLink
  }
`;

ProfileList.NylasType = gql`
  query NylasTypeQuery {
    nylasAccount
    myProfile {
      id
      nylasIsSyncing
    }
  }
`;

ProfileList.NylasIntegration = gql`
  query NylasIntegration {
    nylasAuthLink,
    myProfile {
      ${ProfileList.fragments.profile},
      calendar,
      editToken
    },
    myCoachingProfile {
      ${ProfileList.fragments.coach},
    }
  }
`;

ProfileList.ReadMemberBestPractices = gql`
  mutation readMemberBestPractices {
    readMemberBestPractices {
      ${ProfileList.fragments.profile}
    }
  }
`;

ProfileList.AvailableNylasCalendars = gql`
  query AvailableNylasCalendars($readOnly: Boolean) {
    availableNylasCalendars(readOnly: $readOnly) {
      calendarId
      name
    }
  }
`;

ProfileList.AvailableGoogleCalendars = gql`
  query AvailableGoogleCalendars($googleId: String) {
    availableGoogleCalendars(googleId: $googleId) {
      id
      summary
    }
  }
`;

ProfileList.CurrentCalendarConflicts = gql`
  query CurrentCalendarConflicts($googleId: String) {
    currentCalendarConflicts(googleId: $googleId)
  }
`;

ProfileList.CurrentCalendarBooking = gql`
  query CurrentCalendarBooking {
    currentCalendarBooking
  }
`;

ProfileList.UpdateCalendarConflicts = gql`
  mutation UpdateCalendarConflicts($calendarIds: [String], $googleId: String) {
    updateCalendarConflicts(calendarIds: $calendarIds, googleId: $googleId)
  }
`;

ProfileList.DisconnectGoogleCalendar = gql`
  mutation DisconnectGoogleCalendar($googleId: String) {
    disconnectGoogleCalendar(googleId: $googleId) {
      id
      googleId
      email
    }
  }
`;

ProfileList.UpdateCalendarBooking = gql`
  mutation UpdateCalendarBooking($calendarId: String) {
    updateCalendarBooking(calendarId: $calendarId)
  }
`;

ProfileList.ZoomAuthLink = gql`
  query ZoomAuthLink {
    zoomAuthLink
    myProfile {
      id
      zoomMeetingUrl
    }
  }
`;

ProfileList.GoogleAuthLink = gql`
  query GoogleAuthLink($path: String) {
    googleAuthLink(path: $path)
  }
`;

ProfileList.GoogleContactsLink = gql`
  query GoogleContactsLink {
    googleContactsLink
  }
`;

ProfileList.DeleteNylasToken = gql`
  mutation DeleteNylasToken {
    deleteNylasToken
  }
`;

ProfileList.ToggleOpenEnded = gql`
  mutation ToggleOpenEnded {
    toggleOpenEnded
  }
`;

ProfileList.ToggleApproveSession = gql`
  mutation ToggleApproveSession {
    toggleApproveSession
  }
`;

ProfileList.SimilarCoachQuery = gql`
  query SimilarCoachQuery($coachId: String!) {
    similarCoaches(coachId: $coachId) {
      id
      isAvailable
      topics {
        id
        name
      }
      user {
        id
        slug
        fullName
        bio
        isAdmin
        jobRole
        level
        picture
        linkedin
        role {
          title
          team {
            name
          }
        }
      }
    }
  }
`;

ProfileList.UpdateProfile = gql`
  mutation updateProfile(
    $firstName: String
    $lastName: String
    $bio: String
    $twitter: String
    $linkedin: String
    $website: String
    $mode: String
    $jobRoles: [String]
    $slug: String
  ) {
    updateProfile(
      firstName: $firstName
      lastName: $lastName
      bio: $bio
      twitter: $twitter
      linkedin: $linkedin
      website: $website
      mode: $mode
      jobRoles: $jobRoles
      slug: $slug
    ) {
      id
      bio
      slug
      twitter
      linkedin
      website
      mode
      jobRoles
      name {
        first
        last
      }
    }
  }
`;

ProfileList.UpdateCoachingReason = gql`
  mutation UpdateCoachingReason($reason: String) {
    updateCoachingReason(reason: $reason)
  }
`;

ProfileList.CreateTopic = gql`
  mutation CreateTopic($topic: String) {
    createTopic(topic: $topic) {
      id
      name
      status
      description
      author {
        id
      }
    }
  }
`;

ProfileList.CreateInvite = gql`
  mutation CreateInvite($email: String, $message: String) {
    createInvite(email: $email, message: $message) {
      id
      inviter {
        id
      }
      invitee
      message
      status
      sent
      accepted
    }
  }
`;

ProfileList.UpdateMotivations = gql`
  mutation UpdateCoachingMotivations($motivations: [String]) {
    updateCoachingMotivations(motivations: $motivations)
  }
`;

ProfileList.UpdateHours = gql`
  mutation UpdateHours($openingHours: [OpeningHoursInput]) {
    updateHours(openingHours: $openingHours) {
      days
      start
      end
    }
  }
`;

ProfileList.UpdateBookingNotice = gql`
  mutation UpdateBookingNotice($minNotice: Int) {
    updateMinNotice(minNotice: $minNotice)
  }
`;

ProfileList.CreateRole = gql`
  mutation WorkExperienceMutation(
    $teamName: String
    $teamID: String
    $title: String
    $startDate: String
    $endDate: String
    $teamLogo: String
    $teamDomain: String
  ) {
    createRole(
      teamName: $teamName
      teamID: $teamID
      title: $title
      startDate: $startDate
      endDate: $endDate
      teamLogo: $teamLogo
      teamDomain: $teamDomain
    ) {
      id
      title
      startDate
      endDate
      team {
        id
        name
        logo
        website
      }
    }
  }
`;

ProfileList.UpdateRole = gql`
  mutation WorkExperienceMutation(
    $roleID: String
    $teamName: String
    $teamID: String
    $title: String
    $startDate: String
    $endDate: String
    $description: String
    $teamLogo: String
    $teamDomain: String
    $currentRole: Boolean
    $companySize: String
    $companyIndustry: String
  ) {
    updateRole(
      roleID: $roleID
      teamName: $teamName
      teamID: $teamID
      title: $title
      startDate: $startDate
      endDate: $endDate
      description: $description
      teamLogo: $teamLogo
      teamDomain: $teamDomain
      currentRole: $currentRole
      companySize: $companySize
      companyIndustry: $companyIndustry
    ) {
      id
      title
      startDate
      endDate
      currentRole
      companySize
      companyIndustry
      team {
        id
        name
        logo
        website
      }
    }
  }
`;

ProfileList.DeleteRole = gql`
  mutation WorkExperienceMutation($roleID: String) {
    deleteRole(roleID: $roleID) {
      id
      title
      startDate
      endDate
      team {
        id
        name
        logo
        website
      }
    }
  }
`;

ProfileList.UpDownRole = gql`
  mutation UpDownRole($roleID: String, $direction: String) {
    upDownRole(roleID: $roleID, direction: $direction) {
      id
      title
      startDate
      endDate
      team {
        id
        name
        logo
        website
      }
    }
  }
`;

ProfileList.CreateDegree = gql`
  mutation DegreeMutation(
    $schoolName: String
    $schoolID: String
    $fieldName: String
    $majorName: String
    $start: String
    $end: String
    $schoolLogo: String
    $schoolDomain: String
  ) {
    createDegree(
      schoolName: $schoolName
      schoolID: $schoolID
      fieldName: $fieldName
      majorName: $majorName
      start: $start
      end: $end
      schoolDomain: $schoolDomain
      schoolLogo: $schoolLogo
    ) {
      id
      school {
        id
        name
        logo
        website
      }
      field
      major
      start
      end
    }
  }
`;

ProfileList.UpdateDegree = gql`
  mutation UpdateDegreeMutation(
    $degreeID: String
    $schoolName: String
    $schoolID: String
    $fieldName: String
    $majorName: String
    $start: String
    $end: String
    $schoolLogo: String
    $schoolDomain: String
  ) {
    updateDegree(
      degreeID: $degreeID
      schoolName: $schoolName
      schoolID: $schoolID
      fieldName: $fieldName
      majorName: $majorName
      start: $start
      end: $end
      schoolDomain: $schoolDomain
      schoolLogo: $schoolLogo
    ) {
      id
      school {
        id
        name
        logo
        website
      }
      field
      major
      start
      end
    }
  }
`;

ProfileList.DeleteDegree = gql`
  mutation DeleteDegreeMutation($degreeID: String!) {
    deleteDegree(degreeID: $degreeID) {
      id
      school {
        id
        name
        logo
        website
      }
      field
      major
      start
      end
    }
  }
`;

ProfileList.UpDownDegree = gql`
  mutation UpDownDegree($degreeID: String, $direction: String) {
    upDownDegree(degreeID: $degreeID, direction: $direction) {
      id
      school {
        id
        name
        logo
        website
      }
      field
      major
      start
      end
    }
  }
`;

ProfileList.IndustryOptions = gql`
  query IndustryOptions {
    industries {
      id
      name
      keyword
    }
  }
`;

ProfileList.TopicOptions = gql`
  query TopicOptions {
    availableTopics {
      id
      name
      description
    }
  }
`;

ProfileList.LeaderFilterOptions = gql`
  query LeaderFilters {
    availableRoles
    availableLevels
    availableSorts
  }
`;

ProfileList.ProfileStrengthQuery = gql`
  query ProfileStrengthQuery {
    videoLink
    myAnswerCount
    myQuestionCount
    myMostRecentBooking {
      id
    }
    myProfile {
      id
      jobRole
      role {
        id
        title
        team {
          id
          name
        }
      }
      socialShare {
        when
        where
      }
    }
    myCoachingProfile {
      id
      status
      bestPractices
    }
    myCandidateProfile {
      id
      status
      specialities {
        skill
      }
    }
  }
`;

ProfileList.ViewCalendar = gql`
  query ViewCalendar($profileID: String!) {
    myProfile {
      ${ProfileList.fragments.profile}
      email
    },
    profile(profileID: $profileID) {
      ${ProfileList.fragments.profile},
      calendar,
    },
    coachByProfileId(profileID: $profileID) {
      ${ProfileList.fragments.coach},
      schedule
    }
  }
`;

ProfileList.CalendarRedirect = gql`
  query CalendarRedirect($profileID: String!) {
    profile(profileID: $profileID) {
      id
      slug
    }
  }
`;

ProfileList.ShareOnSocial = gql`
  mutation ShareOnSocial($where: String) {
    shareOnSocial(where: $where) {
      where
      when
    }
  }
`;

ProfileList.UpdateMyPlace = gql`
  mutation UpdateMyPlace(
    $formatted: String,
    $city: String,
    $region: String,
    $country: String,
    $lat: Float,
    $long: Float,
    $placeId: String,
  ) {
    updateMyPlace(
      formatted: $formatted,
      city: $city,
      region: $region,
      country: $country,
      lat: $lat,
      long: $long,
      placeId: $placeId,
    ) {
      ${ProfileList.fragments.place}
    }
  }
`;

ProfileList.MyPlace = gql`
  query MyPlace {
    myProfile {
      ${ProfileList.fragments.profile},
      place {
        ${ProfileList.fragments.place}
      }
    }
  }
`;

ProfileList.GoogleConnectLink = gql`
  query GoogleConnectLink {
    googleConnectLink
    myProfile {
      id
      googleCalendarDismissed
      googleCalendar {
        id
        googleId
        email
        calendars
      }
    }
  }
`;

ProfileList.ConnectGoogleCalendar = gql`
  mutation ConnectGoogleCalendar($accessToken: String, $refreshToken: String) {
    googleCalendarConnect(accessToken: $accessToken, refreshToken: $refreshToken) {
      id
      googleCalendar {
        id
        googleId
        email
        calendars
      }
    }
  }
`;

ProfileList.DismissConnectionNotif = gql`
  mutation DismissConnectionNotif($googleCalendarDismissed: Boolean) {
    dismissConnectionNotif(googleCalendarDismissed: $googleCalendarDismissed) {
      id
      googleCalendarDismissed
    }
  }
`;

ProfileList.profileFragment = ProfileList.fragments.profile;
ProfileList.coachFragment = ProfileList.fragments.coach;

export default ProfileList;
