import React from "react";
import { Paragraph, defaultTheme, majorScale, Pane, TickCircleIcon, Text } from "evergreen-ui";
import { withAnswerCreation } from "./withAnswerCreation";
import Recorder from "./Recorder";

const QuestionCard = (props) => {
  return (
    <Pane
      backgroundColor={defaultTheme.colors.white}
      padding={majorScale(3)}
      gap={majorScale(2)}
      display="flex"
      flexDirection="column"
      borderTopLeftRadius={props.questionNumber === 1 ? majorScale(1) : undefined}
      borderTopRightRadius={props.questionNumber === 1 ? majorScale(1) : undefined}
      borderBottomLeftRadius={props.questionNumber === props.steps.length - 1 ? majorScale(1) : undefined}
      borderBottomRightRadius={props.questionNumber === props.steps.length - 1 ? majorScale(1) : undefined}
    >
      <Pane display="flex" alignItems="center" gap={majorScale(1)}>
        <TickCircleIcon color="success" />

        <Text size={300}>{`QUESTION ${props.questionNumber}`}</Text>
      </Pane>

      <Paragraph size={500}>{props.question.text}</Paragraph>

      <Recorder
        savedDuration={props.answer.duration}
        uri={props.answer.uri}
        rerecordUrl={`/async-screen/${props.question.title}?isRerecord=true`}
      />
    </Pane>
  );
};

export default withAnswerCreation(QuestionCard);
