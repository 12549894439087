import React, { useEffect, useState, useRef } from "react";
import {
  defaultTheme,
  HeadsetIcon,
  ResetIcon,
  SymbolSquareIcon,
  PlayIcon,
  IconButton,
  BanCircleIcon,
  Pane,
  PauseIcon,
  Text,
  Button,
  majorScale
} from "evergreen-ui";
import { useAudioRecorder } from "react-audio-voice-recorder";
import { Link } from "react-router-dom";

const Recorder = (props) => {
  const { startRecording, stopRecording, isRecording, recordingTime, recordingBlob } = useAudioRecorder();
  const [playbackTiming, setPlaybackTiming] = useState(0);
  const [timingInterval, setTimingInterval] = useState();
  const [recording, setRecording] = useState(new Audio(props.audio ? URL.createObjectURL(props.audio) : props.uri));
  const isPlayingRef = useRef(false);
  const [isPaused, setIsPaused] = useState(false);

  // clean up
  useEffect(() => {
    return () => {
      if (recording && isPlayingRef.current) {
        pausePlayback();
        setRecording();
      }
    };
  }, [recording]);

  useEffect(() => {
    if (props.audio && props.savedDuration) {
      props.onSave(props.audio, props.savedDuration);
      setRecording(new Audio(URL.createObjectURL(props.audio)));
    }
  }, [props.audio, props.savedDuration]);

  useEffect(() => {
    if (recordingBlob) {
      props.setAudio(recordingBlob);
    }
  }, [recordingBlob]);

  useEffect(() => {
    if (isRecording && recordingTime >= 60 * 5) {
      props.setSavedDuration(60 * 5);
      stopRecording();
    }
  }, [recordingTime, isRecording]);

  const incrementPlaybackTime = () => {
    return setTimeout(() => {
      setPlaybackTiming((playback) => {
        if (isPlayingRef.current && playback + 1 < props.savedDuration) {
          incrementPlaybackTime();
        }

        return playback + 1;
      });
    }, 1000);
  };

  const stopTiming = () => {
    clearTimeout(timingInterval);
    setTimingInterval();
    isPlayingRef.current = false;
  };

  const playRecording = () => {
    if (!isPaused) {
      setPlaybackTiming(0);
    }
    setIsPaused(false);
    isPlayingRef.current = true;
    recording.play();

    setTimingInterval(incrementPlaybackTime);
    recording.addEventListener("pause", stopTiming);
    recording.addEventListener("ended", () => {
      setIsPaused(false);
      stopTiming();
    });
  };

  const pausePlayback = () => {
    recording.pause();
    setIsPaused(true);
  };

  const saveRecording = () => {
    props.setSavedDuration(recordingTime);
    stopRecording();
  };

  const cancelRecording = () => {
    stopRecording();
    setTimeout(clearRecording, 250); // hack
  };

  const restartRecording = () => {
    isPlayingRef.current = false;
    stopRecording();
    clearRecording();
    startRecording();
  };

  const clearRecording = () => {
    props.setAudio();
    props.setSavedDuration(0);
  };

  const renderRecordingTiming = (timing) => {
    return (
      <Pane>
        <Text color={defaultTheme.colors.gray900}>
          <Text color={defaultTheme.colors.gray700}>{`${Math.floor(timing / 60)}:${timing % 60 < 10 ? 0 : ""}${timing % 60} `}</Text>/ 5:00
        </Text>
      </Pane>
    );
  };

  const renderPlaybackTiming = (timing, duration) => {
    return (
      <Pane>
        <Text color={defaultTheme.colors.gray900}>
          <Text
            color={defaultTheme.colors.gray700}
          >{`${Math.floor(timing / 60)}:${timing % 60 < 10 ? 0 : ""}${timing % 60} / ${Math.floor(duration / 60)}:${duration % 60 < 10 ? 0 : ""}${duration % 60}`}</Text>
        </Text>
      </Pane>
    );
  };

  const renderRecordingSection = () => {
    if (isRecording) {
      return (
        <>
          <Button appearance="primary" iconBefore={SymbolSquareIcon} onClick={saveRecording}>
            Save
          </Button>

          <Button iconBefore={BanCircleIcon} onClick={cancelRecording}>
            Cancel
          </Button>

          {renderRecordingTiming(recordingTime)}
        </>
      );
    } else if (props.audio || props.uri) {
      return (
        <>
          {isPlayingRef.current ? (
            <IconButton intent="none" appearance="primary" icon={<PauseIcon color={defaultTheme.colors.white} />} onClick={pausePlayback} />
          ) : (
            <IconButton intent="none" appearance="primary" icon={<PlayIcon color={defaultTheme.colors.white} />} onClick={playRecording} />
          )}

          {renderPlaybackTiming(playbackTiming, props.savedDuration)}

          <Button
            appearance="minimal"
            iconBefore={ResetIcon}
            onClick={props.uri ? undefined : restartRecording}
            is={props.uri ? Link : undefined}
            to={props.uri ? props.rerecordUrl : undefined}
          >
            Re-record
          </Button>
        </>
      );
    }

    return (
      <>
        <Button appearance="primary" iconBefore={HeadsetIcon} onClick={startRecording}>
          Record voice
        </Button>

        {renderRecordingTiming(recordingTime)}
      </>
    );
  };

  return (
    <Pane display="flex" flexDirection="row" alignItems="center" gap={majorScale(2)}>
      {renderRecordingSection()}
    </Pane>
  );
};

export default Recorder;
