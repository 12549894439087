import { FFmpeg } from "@ffmpeg/ffmpeg";

export const convertToDownloadFileExtension = async (blob, title) => {
  const ffmpeg = new FFmpeg();
  await ffmpeg.load();

  const inputName = "input.webm";
  const outputName = `${title}.webm`;

  await ffmpeg.writeFile(inputName, new Uint8Array(await blob.arrayBuffer()));
  await ffmpeg.exec(["-i", inputName, outputName]);

  const outputData = await ffmpeg.readFile(outputName);
  const outputBlob = new Blob([outputData.buffer], {
    type: `audio/webm`
  });

  return outputBlob;
};
