import _ from "lodash";
import React from "react";
import { useMutation } from "@apollo/client";
import * as Yup from "yup";
import { Formik, Field, Form } from "formik";
import { Dialog, Pane, Heading, RadioGroup, Badge, Paragraph, toaster, majorScale } from "evergreen-ui";

import CandidateQueries from "../../../../queries/candidate";
import ProfileList from "../../../../queries/profile/ProfileList";

const jobSearchModes = [
  { value: "active", label: "Ready to interview and actively searching" },
  { value: "passive", label: "Open to exploring new opportunities" },
  { value: "inactive", label: "Not open to considering new opportunities" }
  // { value: "hiring", label: "I’m trying to hire" },
];

const showSuccess = (copy) =>
  _.debounce(() => {
    toaster.success(copy, { duration: 2, id: copy });
  }, 2000);

const EditJobSearchStatus = ({ myCandidateProfile }) => {
  const [updateJobSearchStatus] = useMutation(CandidateQueries.mutations.SetJobSearchMode, {
    onCompleted: () => {
      showSuccess("Job search status updated.")();
    },
    refetchQueries: [{ query: ProfileList.MyProfileQuery }, { query: CandidateQueries.queries.MyCandidateProfile }],
    awaitRefetchQueries: true
  });

  const handleSubmit = (values, { setSubmitting }) => {
    updateJobSearchStatus({
      variables: {
        jobSearchMode: values.jobSearchMode
      }
    });
  };

  return (
    <Formik
      initialValues={{
        jobSearchMode: myCandidateProfile?.status || "inactive"
      }}
      validationSchema={Yup.object().shape({
        jobSearchMode: Yup.string().required()
      })}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, setFieldValue, submitForm }) => (
        <Form>
          <Pane>
            <Heading>Are you currently looking for a job?</Heading>
            <Paragraph>We can add you to our talent directory and connect you with jobs. You can disable this any time.</Paragraph>
          </Pane>
          <Field name="jobSearchMode">
            {({ field, form }) => (
              <RadioGroup
                defaultValue={values?.jobSearchMode}
                options={jobSearchModes}
                onChange={(e) => {
                  setFieldValue("jobSearchMode", e.target.value, false);
                  submitForm();
                }}
              />
            )}
          </Field>
          {touched["jobSearchMode"] && errors["jobSearchMode"] && (
            <Badge color="red" marginTop={majorScale(1)} marginBottom={majorScale(1)}>
              {errors["jobSearchMode"]}
            </Badge>
          )}
        </Form>
      )}
    </Formik>
  );
};

const EditJobSearch = ({ showJobSearch, setShowJobSearch, myCandidateProfile }) => {
  return (
    <Dialog
      isShown={showJobSearch}
      title="Job search status"
      onCloseComplete={() => setShowJobSearch(false)}
      confirmLabel="Finish updating"
      hasCancel={false}
      hasClose={false}
      preventBodyScrolling={true}
    >
      <EditJobSearchStatus myCandidateProfile={myCandidateProfile} />
    </Dialog>
  );
};

export default EditJobSearch;
