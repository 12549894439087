import { Container, Row, Col, ScreenClassRender } from "react-grid-system";
import { majorScale } from "evergreen-ui";

const withAuthLayout = (AuthScreen) =>
  function createWithAuthLayoutHoc(props) {
    return (
      <ScreenClassRender
        render={(screenClass) => (
          <Container style={{ width: "100%" }}>
            <Row style={{ paddingTop: majorScale(10) }} justify="center">
              <Col xs={12} sm={10} md={8} lg={7} style={{ maxWidth: "560px" }}>
                <AuthScreen screenClass={screenClass} {...props} />
              </Col>
            </Row>
          </Container>
        )}
      />
    );
  };

export default withAuthLayout;
