import React from "react";
import {
  defaultTheme,
  majorScale,
  minorScale,
  StarIcon,
  StarEmptyIcon,
  CalendarIcon,
  Avatar,
  Text,
  Pane,
  BriefcaseIcon,
  Button,
  CrownIcon,
  Strong
} from "evergreen-ui";
import { useMutation } from "@apollo/client";
import { Link } from "react-router-dom";
import LocationPane from "../../components/async-screen/LocationPane";
import CoachList from "../../routes/coach/CoachList";

const MentorCard = (props) => {
  const [updateSave, { loading: isSaving }] = useMutation(
    props.mentor.mySave?.status === "Active" ? CoachList.UnsaveMentor : CoachList.SaveMentor,
    {
      awaitRefetchQueries: true,
      refetchQueries: props.refetchQueries
    }
  );

  return (
    <Pane
      backgroundColor={defaultTheme.colors.white}
      borderRadius={majorScale(1)}
      padding={majorScale(2)}
      display="flex"
      flexDirection="column"
      gap={majorScale(2)}
      flex="calc(50% - 8px)"
    >
      <Pane display="flex" gap={majorScale(2)} alignItems="center">
        <Avatar size={majorScale(8)} src={props.mentor.user.picture} />

        <Pane display="flex" flexDirection="column" gap={majorScale(1)}>
          <Strong color={defaultTheme.colors.gray900}>{`${props.mentor.user.fullName} (${props.mentor.user.pronouns})`}</Strong>

          {props.mentor.user?.location ? <LocationPane location={props.mentor.user.location} /> : undefined}
        </Pane>
      </Pane>

      <Pane display="flex" flexDirection="column" gap={minorScale(3)}>
        {props.mentor.user?.role?.currentRole ? (
          <Pane display="flex" alignItems="center" gap={majorScale(1)}>
            <Avatar src={props.mentor.user?.role?.team?.logo} size={majorScale(2)} />

            <Text color={defaultTheme.colors.gray900}>{props.mentor.user.role?.team?.name}</Text>

            <Text color={defaultTheme.colors.gray700}>{props.mentor.user?.role?.companySize}</Text>
          </Pane>
        ) : undefined}

        <Pane display="flex" alignItems="center" gap={majorScale(1)}>
          <BriefcaseIcon color={defaultTheme.colors.gray600} />

          <Text>{props.mentor.user?.role?.title}</Text>
        </Pane>

        <Pane display="flex" alignItems="center" gap={majorScale(1)}>
          <CrownIcon color={defaultTheme.colors.gray600} />

          <Text>{`${props.mentor.totalSessions} session${props.mentor.totalSessions === 1 ? "" : "s"}`}</Text>
        </Pane>

        <Pane display="flex" alignItems="center" gap={majorScale(1)}>
          <CalendarIcon color={defaultTheme.colors.gray600} />

          <Text>{`Next available ${new Date(props.mentor.nextAvailable.start).toLocaleString("en-US", { weekday: "short", month: "short", day: "numeric", hour: "numeric", minute: "numeric" })}`}</Text>
        </Pane>
      </Pane>

      <Pane display="flex" justifyContent="space-between">
        <Button
          isLoading={isSaving}
          appearance="minimal"
          iconBefore={props.mentor.mySave?.status === "Active" ? StarIcon : StarEmptyIcon}
          onClick={() => updateSave({ variables: { mentorId: props.mentor?.id } })}
        >
          {props.mentor.mySave?.status === "Active" ? "Saved" : "Save"}
        </Button>

        <Button is={Link} to={`/p/${props.mentor.user.slug}`} iconBefore={CalendarIcon} appearance="primary" intent="none">
          View calendar
        </Button>
      </Pane>
    </Pane>
  );
};

export default MentorCard;
