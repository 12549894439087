import _ from "lodash";
import React from "react";
import { Container, Row, Col, Hidden } from "react-grid-system";
import {
  Avatar,
  Button,
  CalendarIcon,
  CaretDownIcon,
  ChatIcon,
  Heading,
  IconButton,
  Menu,
  Pane,
  PeopleIcon,
  PersonIcon,
  Popover,
  majorScale
} from "evergreen-ui";
import { withRouter, Link as BrowserLink } from "react-router-dom";
import { logo } from "../../content/about";

let NAV_LINKS = [
  { link: "/discover", title: "Discover" },
  { link: "/async-screen/dashboard", title: "Get hired" },
  { link: "/mentors", title: "Mentors" },
  { link: "/discussions", title: "Discussions" }
];

const RECRUITER_LINKS = [
  { link: "/activity/active", title: "Activity" },
  { link: "/candidates/all", title: "Talent" }
];

const NavLink = withRouter(({ to, children, location }) => {
  const isActive = location.pathname.includes(to);
  return (
    <Pane paddingX={12} paddingY={16} is={BrowserLink} to={to} textDecoration="none">
      <Heading size={400} fontWeight={500} color={isActive ? "#1E1EC6" : "#101840"}>
        {children}
      </Heading>
    </Pane>
  );
});

const ProfileLink = ({ to, children, location }) => {
  return (
    <Menu.Item is={BrowserLink} to={to}>
      <Pane color="#101840">{children}</Pane>
    </Menu.Item>
  );
};

const ProfileDropdown = ({ myProfile, isCoach, isRecruiter }) => {
  const profileUrl = { link: `/p/${myProfile?.slug}/edit`, title: "My Profile" };

  return (
    <Popover
      content={
        <Menu>
          {!isRecruiter && (
            <Hidden lg xl>
              <Menu.Group>
                {NAV_LINKS.map(({ title, link }, index) => (
                  <ProfileLink to={link} key={index}>
                    {title}
                  </ProfileLink>
                ))}
                <Menu.Divider />
              </Menu.Group>
            </Hidden>
          )}
          {isRecruiter ? (
            <Menu.Group>
              <ProfileLink to={"/settings"}>Account Settings</ProfileLink>
              <ProfileLink to={"/logout"}>Logout</ProfileLink>
            </Menu.Group>
          ) : (
            <>
              <Menu.Group>
                {profileUrl?.link && <ProfileLink to={profileUrl?.link}>{profileUrl?.title}</ProfileLink>}
                {isCoach && <ProfileLink to={"/mentor/settings"}>Mentor Settings</ProfileLink>}
                <ProfileLink to={isCoach ? "/guide/mentor" : "/guide/member"}>Best Practices</ProfileLink>
                <ProfileLink to={"/settings"}>Account Settings</ProfileLink>
              </Menu.Group>
              <Menu.Divider />
              <Menu.Group>
                <ProfileLink to={"/logout"}>Logout</ProfileLink>
              </Menu.Group>
            </>
          )}
        </Menu>
      }
    >
      <Pane display="flex" alignItems="center" cursor="pointer">
        <Avatar size={majorScale(5)} name={myProfile?.fullName} src={myProfile?.picture} />
        <CaretDownIcon />
      </Pane>
    </Popover>
  );
};

const NewNavigation = ({ myProfile, isCoach, isRecruiter, isActiveCandidate, logged }) => {
  let headerLink;

  if (!logged) {
    headerLink = "/";
  } else if (isRecruiter) {
    headerLink = "/activity/active";
  } else if (isCoach) {
    headerLink = "/dashboard";
  } else {
    headerLink = "/discover";
  }

  // if (isActiveCandidate) {
  //   NAV_LINKS = _.uniqWith([...NAV_LINKS, { link: "/activity/active", title: "Opportunities" }], _.isEqual);
  // }

  const ITEMS = isRecruiter ? RECRUITER_LINKS : NAV_LINKS;

  return (
    <Pane backgroundColor="white" paddingY={12} borderBottom="1px solid #EDEFF5" width="100%">
      <Container>
        <Row align="center">
          <Col xs={6} lg={2}>
            <Pane display="flex" alignItems="center" is={BrowserLink} to={headerLink} textDecoration="none">
              <img src={logo} alt={"Merit"} height={majorScale(3)} />
              {/* <Heading
                size={600}
                className="dm-sans"
                marginLeft={12}>
                Merit
              </Heading> */}
            </Pane>
          </Col>
          <Hidden xs sm md>
            <Col lg={8}>
              <Pane display="flex" justifyContent="center">
                {ITEMS.map(({ title, link }, index) => (
                  <NavLink to={link} key={index}>
                    {title}
                  </NavLink>
                ))}
              </Pane>
            </Col>
          </Hidden>
          <Col xs={6} lg={2}>
            <Pane display="flex" justifyContent="flex-end" alignItems="center">
              {!isRecruiter && (
                <>
                  <IconButton appearance="minimal" icon={PeopleIcon} is={BrowserLink} to={"/connections"} />
                  <IconButton appearance="minimal" icon={CalendarIcon} is={BrowserLink} to={"/sessions"} />
                  <IconButton appearance="minimal" icon={ChatIcon} is={BrowserLink} to={"/messages"} />
                </>
              )}
              <ProfileDropdown myProfile={myProfile} isCoach={isCoach} isRecruiter={isRecruiter} />
            </Pane>
          </Col>
        </Row>
      </Container>
    </Pane>
  );
};

export default NewNavigation;
