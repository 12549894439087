import { Button, Checkbox, Pane, Text, minorScale, majorScale, defaultTheme } from "evergreen-ui";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Hidden, Visible } from "react-grid-system";

const CheckboxButton = (props) => {
  const color = props.isActive ? defaultTheme.colors.blue400 : undefined;

  return (
    <Button
      type="button"
      height={majorScale(10)}
      display="flex"
      flexDirection="row"
      width="100%"
      gap={minorScale(4)}
      justifyContent="flex-start"
      style={{ backgroundColor: props.isActive ? "#F3F6FF" : undefined }}
      {...props}
    >
      <Pane display="flex" justifyContent="flex-start" height="100%">
        <Checkbox checked={props.isActive} marginTop="20px" />
      </Pane>

      <Visible xs sm>
        <Pane display="flex" flexDirection="row" gap={minorScale(2)} alignItems="center">
          <Pane>
            <FontAwesomeIcon color={color} height={16} icon={props.icon} />
          </Pane>

          <Text size={300} color={color}>
            {props.label}
          </Text>
        </Pane>
      </Visible>

      <Hidden xs sm>
        <Pane display="flex" flexDirection="column" gap={minorScale(2)} alignItems="flex-start">
          <Pane>
            <FontAwesomeIcon color={color} height={16} icon={props.icon} />
          </Pane>

          <Text size={300} color={color}>
            {props.label}
          </Text>
        </Pane>
      </Hidden>
    </Button>
  );
};

export default CheckboxButton;
