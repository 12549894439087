import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { Row, Col, useScreenClass } from "react-grid-system";
import { withRouter, Link } from "react-router-dom";
import { Paragraph, Heading, Spinner, majorScale, Pane, Text, minorScale, Switch, Button } from "evergreen-ui";
import Steps from "../../components/async-screen/Steps";
import { AsyncScreenQueries } from "../../queries/async-screen";
import AsyncScreenJob from "../../components/async-screen/AsyncScreenJob";
import QuestionCard from "../../components/async-screen/QuestionCard";

const AsyncScreenReviewPage = (props) => {
  const { loading, data } = useQuery(AsyncScreenQueries.queries.GetCommonApplication, {
    fetchPolicy: "network-only"
  });
  const [shareForOtherRoles, setShareForOtherRoles] = useState(true);
  const questions = data?.commonApplication.answers.map((answer) => answer.question) || [];
  const steps = [...questions.map((question) => question.title), "Review"];
  const queryParams = new URLSearchParams(props.location.search);
  const screenClass = useScreenClass();
  const [setShareCommonApplication] = useMutation(AsyncScreenQueries.mutations.ShareCommonApplication);

  useEffect(() => {
    if (data?.commonApplication) {
      setShareForOtherRoles(data?.commonApplication.canShare);
    }
  }, [data?.commonApplication?.canShare]);

  if (loading) {
    return <Spinner />;
  }

  const renderQuestionBlocks = () => {
    return data?.commonApplication.answers.map((answer, idx) => (
      <QuestionCard key={idx} answer={answer} question={answer.question} questionNumber={idx + 1} steps={steps} />
    ));
  };

  return (
    <Col style={{ paddingTop: majorScale(5), paddingBottom: majorScale(5), display: "flex", flexDirection: "column", gap: majorScale(4) }}>
      {/* Layout uses Container and Row so we need Col as the parent node */}
      <Steps steps={steps} currentStepIndex={steps.length - 1} />

      <Row>
        <Col>
          <Heading size={700}>Alomst done!</Heading>

          <Paragraph size={500}>Ready to go? Hit “publish” below. You can re-record your answers later.</Paragraph>
        </Col>
      </Row>

      <Pane display="flex" flexDirection="column" gap={minorScale(1)}>
        {renderQuestionBlocks()}
      </Pane>

      {queryParams.get("jobId") ? (
        <Pane display="flex" flexDirection="column" gap={majorScale(2)}>
          <Heading size={400}>Your responses will be sent to the Merit team for:</Heading>

          <AsyncScreenJob />
        </Pane>
      ) : undefined}

      <Pane>
        <Row>
          <Col
            xs={12}
            lg={10}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: minorScale(3),
              marginBottom: ["xs", "sm", "md"].includes(screenClass) ? majorScale(4) : 0
            }}
          >
            <Switch checked={shareForOtherRoles} onChange={() => setShareForOtherRoles(!shareForOtherRoles)} />

            <Text>Share with hiring managers for other relevant roles</Text>
          </Col>

          <Col xs={12} lg={2}>
            <Button
              is={Link}
              to="/async-screen/dashboard"
              appearance="primary"
              width="100%"
              onClick={() => setShareCommonApplication({ variables: { canShare: shareForOtherRoles } })}
            >
              Publish
            </Button>
          </Col>
        </Row>
      </Pane>
    </Col>
  );
};

export default withRouter(AsyncScreenReviewPage);
