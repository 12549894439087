import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { convertToDownloadFileExtension } from "../../routes/async-screen/utils";
import { AsyncScreenQueries } from "../../queries/async-screen";

export const withAnswerCreation = (AsynScreenComponent) => {
  return withRouter((props) => {
    const [putCommonApplicationWithAnswer] = useMutation(AsyncScreenQueries.mutations.CommonApplication, {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: AsyncScreenQueries.queries.CommonApplication
        }
      ]
    });
    const [isUploading, setIsUploading] = useState(false);

    const uploadAnswer = (presignedUrl, questionId) => async (audio, savedDuration) => {
      const webmBlob = await convertToDownloadFileExtension(audio, props.match.params.step);
      setIsUploading(true);
      return fetch(presignedUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "multipart/form-data",
          "Content-Length": webmBlob.size
        },
        body: webmBlob
      })
        .then((res) => {
          const url = new URL(res.url);
          return putCommonApplicationWithAnswer({
            variables: {
              questionId,
              pathname: url.pathname,
              duration: savedDuration
            }
          });
        })
        .then(() => {
          // TODO: add success notification
        })
        .finally(() => {
          setIsUploading(false);
        });
    };

    return <AsynScreenComponent uploadAnswer={uploadAnswer} isUploading={isUploading} {...props} />;
  });
};
