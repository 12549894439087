import _ from "lodash";
import React, { useEffect } from "react";
import queryString from "query-string";
import { useMutation, useQuery } from "@apollo/client";
import { Pane, Button, Heading, majorScale, minorScale, defaultTheme } from "evergreen-ui";
import { Row, Col, Container } from "react-grid-system";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import Cookies from "js-cookie";
import { withRouter } from "react-router-dom";

import withAuthLayout from "./withAuthLayout";
import AuthQueries from "../../queries/auth";
import ProfileList from "../../queries/profile/ProfileList";
import TextInputField from "../../components/form/TextInputField";

const googleBtn = "https://ucarecdn.com/29e213d0-2fc2-4c1a-93fc-b0af36c28b33/btn_google_signin_light_normal_web2x.png";

const CreateAccountSchema = Yup.object().shape({
  email: Yup.string().email("Provide your personal email").required("Include your personal email"),
  firstName: Yup.string()
    .min(1, "Include your first name")
    .max(100, "Shorten the first name you put in")
    .required("Provide your first name"),
  lastName: Yup.string().min(1, "Include your last name").max(100, "Shorten the last name you put in").required("Provide your last name"),
  password: Yup.string().min(4, "Include your new password").max(50, "Shorten the password you use").required("Include a password")
});

const GoogleAuthButton = ({ screenClass }) => {
  const { data, loading, error } = useQuery(ProfileList.GoogleAuthLink);
  const width = screenClass === "xs" ? "100%" : majorScale(24);

  if (loading || error) {
    return <img src={googleBtn} width={width} alt="google" />;
  } else if (data) {
    const { googleAuthLink } = data;

    return (
      <a href={googleAuthLink}>
        <img src={googleBtn} width={width} alt="google" />
      </a>
    );
  }
};

const SignupPage = (props) => {
  const { mode } = queryString.parse(props.location.search, { arrayFormat: "comma" });
  const [createAccount] = useMutation(AuthQueries.mutations.CreateAccount, {
    awaitRefetchQueries: true,
    onCompleted: ({ createAccount }) => {
      if (window && window.analytics) {
        window.analytics.track("Create Account");

        if (_.isEqual("ACCOUNT_EXISTS", createAccount)) {
          // If the account already exists, redirect them to login
          let nextPage = "/login?accountExists=true";

          // TODO: make mode an enum
          if (mode === "Coach" || mode === "Both") {
            // TODO: we probably don't want to redirect here if user is already a mentor
            nextPage += "&origin=/apply/0";
          }
          props.history.push(nextPage);
        } else {
          // Give the user a temporary jwt to use for subsequent
          // profile requests and edits
          const jwt = createAccount;
          Cookies.set("token", jwt, { expires: 2 });
          props.history.push("/candidate/setup");
        }
      }
    }
  });

  useEffect(() => {
    window.analytics.track("View Signup Page");
  });

  return (
    <Formik
      validationSchema={CreateAccountSchema}
      initialValues={{
        email: "",
        firstName: "",
        lastName: "",
        password: ""
      }}
      onSubmit={(variables, { setSubmitting }) => {
        createAccount({ variables });
        setSubmitting(false);
      }}
      component={({ isSubmitting }) => (
        <Form>
          <Container>
            <Pane
              backgroundColor={"white"}
              borderRadius={minorScale(3)}
              border={`1px solid ${defaultTheme.colors.gray200}`}
              padding={minorScale(9)}
              display="flex"
              flexDirection="column"
              gap={minorScale(9)}
            >
              <Pane display="flex" flexDirection="column">
                <Heading size={900} marginBottom={minorScale(3)}>
                  Nice to meet you! 🙌🏾
                </Heading>

                <Heading size={400} color={defaultTheme.colors.gray700}>
                  Create your account below.
                </Heading>
              </Pane>

              <Pane
                display="flex"
                alignItems="center"
                paddingBottom={minorScale(9)}
                borderBottom={`1px solid ${defaultTheme.colors.gray300}`}
              >
                <GoogleAuthButton screenClass={props.screenClass} />
              </Pane>

              <Field type="text" name="email" label="Email" placeholder="Enter your email address" component={TextInputField} />

              <Row style={{ display: "flex", gap: "xs" === props.screenClass ? minorScale(9) : null }}>
                <Col xs={12} sm={6}>
                  <Field
                    type="text"
                    name="firstName"
                    label="First name"
                    placeholder="Enter your preferred name"
                    component={TextInputField}
                    containerStyles={{ width: "100%" }}
                  />
                </Col>

                <Col xs={12} sm={6}>
                  <Field
                    type="text"
                    name="lastName"
                    label="Last name"
                    placeholder="Enter your last name"
                    component={TextInputField}
                    containerStyles={{ width: "100%" }}
                  />
                </Col>
              </Row>

              <Field type="password" name="password" label="Password" placeholder="Enter a strong password" component={TextInputField} />

              <Row>
                <Col xs={12} style={{ justifyContent: "flex-end", display: "flex" }}>
                  <Button
                    width={["xs", "sm", "md"].includes(props.screenClass) ? "100%" : undefined}
                    appearance="primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Create account
                  </Button>
                </Col>
              </Row>
            </Pane>
          </Container>
        </Form>
      )}
    />
  );
};

export default withRouter(withAuthLayout(SignupPage));
