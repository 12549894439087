import gql from "graphql-tag";

import ProfileList from "../profile/ProfileList";
import ConnectionQueries from "../connection";
import QueryUtils from "../utils";

const CandidateQueries = {
  fragments: {},
  queries: {},
  mutations: {}
};

CandidateQueries.fragments.CandidateProfile = `
  id,
  status,
  hasResume,
  employmentType,
  employmentPreference,
  minimumSalary,
  minimumHourlyRate,
  visaStatus,
  requiresSponsorship,
  workStyle,
  workStylePreference,
  timezonePreference,
  yearsOfExperience,
  openToRelocation,
  readingResume,
  mustHave {
    attribute,
    category
  },
  niceToHave {
    attribute,
    category
  },
  doNotWant {
    attribute,
    category
  },
  locationPreference {
    id,
    formatted,
    city,
    region,
    country,
    lat,
    long,
    placeId,
  },
  frameworks,
  jobRole,
  specialities {
    skill,
    jobRole,
    proficiency
  },
`;

CandidateQueries.fragments.CandidateRequest = `
  id,
  status,
  message,
  jobListing {
    url,
    title,
  },
  salary {
    canMatch,
    minimumSalary,
    minimumHourlyRate,
  },
  candidate {
    ${CandidateQueries.fragments.CandidateProfile},
    user {
      ${ProfileList.fragments.profile}
    }
  },
  recruiter {
    id,
    team {
      id,
      name
    },
    user {
      ${ProfileList.fragments.profile}
    }
  },
  createdAt,
  updatedAt,
`;

CandidateQueries.queries.MyCandidateProfile = gql`
  query CandidateProfile($profileId: String) {
    myCandidateProfile(profileId: $profileId) {
      ${CandidateQueries.fragments.CandidateProfile},
      resume {
        filename,
        url,
      },
      portfolio,
      automatedFields,
      existingRequest {
        ${CandidateQueries.fragments.CandidateRequest}
      }
      hiddenCompanies {
        id,
        logo,
        name,
        website,
      },
    }
  }
`;

CandidateQueries.fragments.FormOption = `
  value,
  label
`;

CandidateQueries.queries.CandidateProfileOptions = gql`
  query CandidateProfileOptions {
    availableVisaTypes {
      ${CandidateQueries.fragments.FormOption}
    },
    availableWorkStyles {
      ${CandidateQueries.fragments.FormOption}
    },
    availableTeamFeatures {
      attribute,
      category
    },
    availableTeamFeatureCategories {
      ${CandidateQueries.fragments.FormOption}
    },
    availableSkills {
      attribute,
      category
    },
    availableYearsOfExperience,
    availableJobRoles,
    availableLevels,
    availableFrameworks
  }
`;

CandidateQueries.queries.MyRecruiterProfile = gql`
  query MyRecruiterProfile {
    myRecruiterProfile {
      id
      status
    }
  }
`;

const CandidateSearchQuery = {
  vars: `
    $roles: [String],
    $experience: [String],
    $skills: [String],
    $levels: [String],
    $industry: [String],
    $frameworks: [String],
    $companySize: [String],
    $remote: Boolean,
    $relocation: Boolean,
    $status: String,
    $lat: Float,
    $lon: Float,
  `,
  args: `
    roles: $roles,
    experience: $experience,
    skills: $skills,
    industry: $industry,
    frameworks: $frameworks,
    companySize: $companySize,
    levels: $levels,
    remote: $remote,
    relocation: $relocation,
    status: $status,
    lat: $lat,
    lon: $lon,
  `
};

CandidateQueries.queries.CandidateSearch = gql`
  query CandidateSearch($cursor: String, ${CandidateSearchQuery.vars}) {
    candidateSearch(cursor: $cursor, ${CandidateSearchQuery.args}) {
      edges {
        ${CandidateQueries.fragments.CandidateProfile},
        resume {
          url,
          filename
        },
        existingRequest {
          ${CandidateQueries.fragments.CandidateRequest}
        },
        user {
          ${ProfileList.fragments.profile}
        },
        recommendations {
          ${ConnectionQueries.fragments.ConnectionRecommendation}
        }
      },
      ${QueryUtils.PageInfo}
    }
  }
`;

CandidateQueries.queries.MyCandidateRequests = gql`
  query MyCandidateRequests($cursor: String, $status: String, $recruiterId: String) {
    myCandidateRequests(cursor: $cursor, status: $status, recruiterId: $recruiterId) {
      edges {
        ${CandidateQueries.fragments.CandidateRequest}
      },
      ${QueryUtils.PageInfo}
    }
  }
`;

CandidateQueries.queries.CandidateRequestById = gql`
  query CandidateRequestById($id: String, $recruiterId: String) {
    candidateRequestById(id: $id, recruiterId: $recruiterId) {
      ${CandidateQueries.fragments.CandidateRequest}
    }
  }
`;

CandidateQueries.mutations.CreateCandidateProfile = gql`
  mutation CreateCandidateProfile(
    $place: PlaceInput, 
    $mode: String, 
    $jobSearchMode: String,
    $linkedin: String
  ) {
    createCandidateProfile(
      linkedin: $linkedin,
      mode: $mode,
      jobSearchMode: $jobSearchMode,
      place: $place
    ) {
      ${CandidateQueries.fragments.CandidateProfile}
    }
  }
`;

CandidateQueries.mutations.UpdateCandidateProfile = gql`
  mutation UpdateCandidateProfile($jobRole: String, $visaStatus: String, $challenges: [String]) {
    updateCandidateProfile(jobRole: $jobRole, visaStatus: $visaStatus, challenges: $challenges) {
      jobRole
      topicIds
    }
  }
`;

CandidateQueries.mutations.SetJobSearch = gql`
  mutation SetJobSearch($challenges: [String], $context: String) {
    setJobSearch(challenges: $challenges, context: $context) {
      id
    }
  }
`;

CandidateQueries.mutations.SetJobSearchMode = gql`
  mutation SetJobSearchMode($jobSearchMode: String) {
    setJobSearchMode(jobSearchMode: $jobSearchMode) {
      id
    }
  }
`;

CandidateQueries.mutations.UpdateVisaStatus = gql`
  mutation UpdateVisaStatus($visaStatus: String) {
    updateWorkStatus(visaStatus: $visaStatus) {
      ${CandidateQueries.fragments.CandidateProfile}
    }
  }
`;

CandidateQueries.mutations.UpdateEmploymentType = gql`
  mutation UpdateEmploymentType($employmentType: [String], $employmentPreference: String, $minimumSalary: Int, $minimumHourlyRate: Int) {
    updateEmploymentType(employmentType: $employmentType, employmentPreference: $employmentPreference, minimumSalary: $minimumSalary, minimumHourlyRate: $minimumHourlyRate) {
      ${CandidateQueries.fragments.CandidateProfile}
    }
  }
`;

CandidateQueries.mutations.UpdateWorkPreferences = gql`
  mutation UpdateWorkPreferences($workStyle: [String], $workStylePreference: String, $timezonePreference: String, $locationPreference: [PlaceInput]) {
    updateWorkPreferences(workStyle: $workStyle, workStylePreference: $workStylePreference, timezonePreference: $timezonePreference, locationPreference: $locationPreference) {
      ${CandidateQueries.fragments.CandidateProfile}
    }
  }
`;

CandidateQueries.mutations.UpdateTeamPreferences = gql`
  mutation UpdateTeamPreferences($mustHave: [FeatureInput], $niceToHave: [FeatureInput], $doNotWant: [FeatureInput]) {
    updateTeamPreferences(mustHave: $mustHave, niceToHave: $niceToHave, doNotWant: $doNotWant) {
      ${CandidateQueries.fragments.CandidateProfile}
    }
  }
`;

CandidateQueries.mutations.UpdateSpecialties = gql`
  mutation UpdateSpecialties($jobRole: String, $specialities: [SpecialityInput]) {
    updateSpecialties(jobRole: $jobRole, specialities: $specialities) {
      ${CandidateQueries.fragments.CandidateProfile}
    }
  }
`;

CandidateQueries.mutations.UpdateFrameworks = gql`
  mutation UpdateFrameworks($frameworks: [String]) {
    updateFrameworks(frameworks: $frameworks) {
      ${CandidateQueries.fragments.CandidateProfile}
    }
  }
`;

CandidateQueries.mutations.UploadDocument = gql`
  mutation UploadDocument($url: String, $filename: String, $type: String, $readResume: Boolean) {
    uploadDocument(url: $url, filename: $filename, type: $type, readResume: $readResume) {
      ${CandidateQueries.fragments.CandidateProfile},
      resume {
        filename,
        url,
      },
      portfolio,
    }
  }
`;

CandidateQueries.mutations.HideOrShowCompany = gql`
  mutation HideOrShowCompany($teamId: String, $domain: String, $logo: String, $name: String, $hide: Boolean) {
    hideOrShowCompany(teamId: $teamId, domain: $domain, name: $name, logo: $logo, hide: $hide) {
      ${CandidateQueries.fragments.CandidateProfile},
      hiddenCompanies {
        id,
        name,
        logo,
        website,
      }
    }
  }
`;

CandidateQueries.mutations.UpdatePortfolio = gql`
  mutation UpdatePortfolio($portfolio: String) {
    updatePortfolio(portfolio: $portfolio) {
      ${CandidateQueries.fragments.CandidateProfile},
      portfolio,
    }
  }
`;

CandidateQueries.mutations.CreateCandidateRequest = gql`
  mutation CreateCandidateRequest(
    $candidateId: String,
    $message: String,
    $jobUrl: String,
    $jobTitle: String,
    $canMatch: Boolean,
    $minimumSalary: Int,
    $minimumHourlyRate: Int
  ) {
    createCandidateRequest(
      candidateId: $candidateId,
      message: $message,
      jobUrl: $jobUrl,
      jobTitle: $jobTitle,
      canMatch: $canMatch,
      minimumSalary: $minimumSalary,
      minimumHourlyRate: $minimumHourlyRate
    ) {
      ${CandidateQueries.fragments.CandidateRequest}
    }
  }
`;

CandidateQueries.mutations.RespondToCandidateRequest = gql`
  mutation RespondToCandidateRequest($id: String, $status: String) {
    respondToCandidateRequest(id: $id, status: $status) {
      ${CandidateQueries.fragments.CandidateRequest}
    }
  }
`;

CandidateQueries.mutations.ArchiveRequest = gql`
  mutation RespondToCandidateRequest($id: String) {
    archiveRequest(id: $id) {
      ${CandidateQueries.fragments.CandidateRequest}
    }
  }
`;

export default CandidateQueries;
