import { Pane, Heading, majorScale, defaultTheme, minorScale, SmallTickIcon } from "evergreen-ui";

const styles = {
  currentStep: {
    indexColor: defaultTheme.colors.blue600,
    indexBackgroundColor: defaultTheme.colors.blue100,
    titleColor: defaultTheme.colors.blue600
  },
  step: {
    indexColor: defaultTheme.colors.muted,
    indexBackgroundColor: defaultTheme.colors.gray200,
    titleColor: defaultTheme.colors.muted
  },
  completedStep: {
    indexColor: defaultTheme.colors.muted,
    indexBackgroundColor: defaultTheme.colors.green100,
    titleColor: defaultTheme.colors.gray700
  }
};

const Steps = (props) => {
  return (
    <Pane flexDirection="row" display="flex" justifyContent="center" gap={majorScale(3)}>
      {props.steps.map((step, idx) => {
        let style = styles.step;
        if (idx < props.currentStepIndex) {
          style = styles.completedStep;
        } else if (idx === props.currentStepIndex) {
          style = styles.currentStep;
        }

        return (
          <Pane key={step} display="flex" gap={majorScale(1)} alignItems="center">
            <Heading
              display="flex"
              size={100}
              backgroundColor={style.indexBackgroundColor}
              borderRadius={4}
              paddingTop={minorScale(1)}
              paddingBottom={minorScale(1)}
              paddingLeft={minorScale(2)}
              paddingRight={minorScale(2)}
              color={style.indexColor}
            >
              {idx < props.currentStepIndex ? <SmallTickIcon /> : idx + 1}
            </Heading>

            <Heading size={300} color={style.titleColor}>
              {step}
            </Heading>
          </Pane>
        );
      })}
    </Pane>
  );
};

export default Steps;
