import { Button, majorScale, Pane, minorScale, Text, defaultTheme } from "evergreen-ui";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Hidden, Visible } from "react-grid-system";

const RadioButton = (props) => {
  const color = props.isActive ? defaultTheme.colors.blue400 : undefined;

  return (
    <Button
      type="button"
      height={majorScale(10)}
      display="flex"
      flexDirection="column"
      width="100%"
      gap={minorScale(4)}
      alignItems="flex-start"
      style={{ backgroundColor: props.isActive ? "#F3F6FF" : undefined }}
      {...props}
    >
      <Visible xs sm>
        <Pane display="flex" alignItems="center" gap={minorScale(2)}>
          <FontAwesomeIcon color={color} height={16} icon={props.icon} />

          <Text color={color} size={300}>
            {props.label}
          </Text>
        </Pane>
      </Visible>

      <Hidden xs sm>
        <Pane>
          <FontAwesomeIcon color={color} height={16} icon={props.icon} />
        </Pane>

        <Text color={color} size={300}>
          {props.label}
        </Text>
      </Hidden>
    </Button>
  );
};

export default RadioButton;
