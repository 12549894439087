import React from "react";
import { Redirect } from "react-router-dom";

import MembersPage from "./playlist";
import OrganizationPage from "./organizations";
import ConductPage from "./conduct";
import BookingPolicy from "./BookingPolicy";
import PrivacyPage from "./privacy";
import PlusPage from "./plus";
import SuccessPage from "./success";
import Layout from "../../components/layout/Layout";

import withRoutes from "../withRoutes";

export default withRoutes(Layout, [
  {
    path: "/climb",
    component: MembersPage,
    state: "Find a mentor",
    exact: true
  },
  {
    path: "/experts",
    exact: true,
    state: "Mentors",
    component: () => <Redirect to="/join-mentors" />
  },
  {
    path: "/Organizations",
    component: OrganizationPage
  },
  {
    path: "/code-of-conduct",
    component: ConductPage,
    state: "Code Of Conduct"
  },
  {
    path: "/privacy-policy",
    component: PrivacyPage,
    state: "Privacy Policy"
  },
  {
    path: "/plus",
    component: PlusPage,
    state: "Merit Plus"
  },
  {
    path: "/success",
    component: SuccessPage,
    state: "Success"
  },
  {
    path: "/booking-policy",
    component: BookingPolicy,
    state: "Booking Policy"
  },
  {
    path: "/:slug",
    component: MembersPage,
    state: "Find a mentor",
    exact: true
  }
]);
