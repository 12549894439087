import withRoutes from "../withRoutes";

import Layout from "../../components/layout/Layout";
import CandidateSetupPage from "./CandidateSetupPage";
import CandidateActivityPage from "./CandidateActivityPage";
import CandidateIndexPage from "./CandidateIndexPage";
import CandidateSetupJobSearchPage from "./CandidateSetupJobSearchPage";

export default withRoutes(Layout, [
  {
    path: "/activity",
    exact: true,
    component: CandidateActivityPage
  },
  {
    path: "/activity/:status",
    component: CandidateActivityPage
  },
  {
    path: "/candidate/setup/job-search",
    component: CandidateSetupJobSearchPage
  },
  {
    path: "/candidate/setup",
    component: CandidateSetupPage
  },
  {
    path: "/candidates/all",
    component: CandidateIndexPage
  }
]);
