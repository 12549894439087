import React from "react";
import { Col, Row } from "react-grid-system";
import {
  defaultTheme,
  Heading,
  majorScale,
  minorScale,
  Pane,
  Switch,
  Text,
  Spinner,
  DocumentIcon,
  WrenchIcon,
  BriefcaseIcon,
  MobileVideoIcon,
  Avatar,
  Button,
  EditIcon,
  Badge
} from "evergreen-ui";
import { Link, Redirect, withRouter } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { AsyncScreenQueries } from "../../queries/async-screen";
import QuestionCard from "../../components/async-screen/QuestionCard";
import MentorCard from "../../components/async-screen//MentorCard";
import LocationPane from "../../components/async-screen/LocationPane";
import ResumeNotif from "../../components/candidate/ResumeNotif";
import Loader from "../../components/Loader";
import PendingConnectionCard from "../../components/async-screen/PendingConnectionCard";
import ActiveConnectionCard from "../../components/async-screen/ActiveConnectionCard";
import ArchivedConnectionCard from "../../components/async-screen/ArchivedConnectionCard";

const refetchQuery = {
  query: AsyncScreenQueries.queries.GetDashboard,
  variables: {
    cursor: "1"
  }
};

const AsyncScreenDashboardPage = () => {
  const { loading, data } = useQuery(refetchQuery.query, {
    variables: refetchQuery.variables,
    fetchPolicy: "network-only"
  });
  const [setShareCommonApplication] = useMutation(AsyncScreenQueries.mutations.ShareCommonApplication, {
    awaitRefetchQueries: true,
    refetchQueries: [refetchQuery]
  });
  const questions = data?.commonApplication?.answers.map((answer) => answer.question) || [];
  const steps = [...questions.map((question) => question.title), "Review"];

  const renderQuestionBlocks = () => {
    return data?.commonApplication.answers.map((answer, idx) => (
      <QuestionCard key={idx} answer={answer} question={answer.question} questionNumber={idx + 1} steps={steps} />
    ));
  };

  const renderFrameworks = () => {
    if (data?.commonApplication.user.candidate.frameworks?.length) {
      return (
        <Pane display="flex" alignItems="center" gap={majorScale(1)}>
          <WrenchIcon color={defaultTheme.colors.gray600} />

          <Text>Frameworks</Text>

          {data?.commonApplication.user.candidate.frameworks.map((framework) => {
            return (
              <Pane display="flex" alignItems="center" gap={majorScale(1)} key={framework}>
                <Text>&bull;</Text>
                <Text>{framework}</Text>
              </Pane>
            );
          })}
        </Pane>
      );
    }
  };

  const renderCurrentRole = () => {
    if (data?.commonApplication.user.role?.currentRole) {
      return (
        <Pane display="flex" alignItems="center" gap={majorScale(1)}>
          <Avatar src={data.commonApplication.user.role.team.logo} size={majorScale(2)} />

          <Text color={defaultTheme.colors.gray900}>{data.commonApplication.user.role.team.name}</Text>
        </Pane>
      );
    }
  };

  const renderMentors = () => {
    return (
      <Pane>
        <Heading marginBottom={majorScale(2)}>Connect with a mentor to get feedback about your profile</Heading>

        <Pane display="flex" flexWrap="wrap" gap={majorScale(2)}>
          {data?.leaders.edges.slice(0, 6).map((mentor) => {
            return (
              <MentorCard
                key={mentor.id}
                mentor={mentor}
                refetchQueries={[
                  {
                    query: AsyncScreenQueries.queries.GetDashboard,
                    variables: {
                      cursor: "1"
                    }
                  }
                ]}
              />
            );
          })}
        </Pane>
      </Pane>
    );
  };

  const renderConnectionsHeading = (title, badgeColor, badgeText) => {
    return (
      <Heading size={400} display="flex" gap={majorScale(1)} alignItems="center">
        {title}

        <Badge color={badgeColor}>{badgeText}</Badge>
      </Heading>
    );
  };

  const renderConnections = () => {
    const pendingConnections = data.commonApplication.connections.filter((connection) => connection.status === "PENDING");
    const activeConnections = data.commonApplication.connections.filter((connection) => connection.status === "ACTIVE");
    const archivedConnections = data.commonApplication.connections.filter((connection) => connection.status === "ARCHIVED");

    return (
      <Pane>
        <Heading marginBottom={minorScale(3)}>Connections</Heading>

        <Pane display="flex" flexDirection="column" gap={majorScale(2)}>
          {pendingConnections.length ? (
            <Pane display="flex" flexDirection="column" gap={minorScale(3)}>
              {renderConnectionsHeading("Requests", "blue", pendingConnections.length)}

              {pendingConnections.map((connection) => (
                <PendingConnectionCard
                  connectionId={connection.id}
                  sentAt={connection.sentAt}
                  key={connection.id}
                  job={connection.job}
                  message={connection.message}
                  recruiter={connection.recruiter}
                />
              ))}
            </Pane>
          ) : undefined}

          {activeConnections.length ? (
            <Pane display="flex" flexDirection="column" gap={minorScale(3)}>
              {renderConnectionsHeading("Active", "neutral", activeConnections.length)}

              {activeConnections.map((connection) => (
                <ActiveConnectionCard
                  connectionId={connection.id}
                  recruiter={connection.recruiter}
                  key={connection.id}
                  job={connection.job}
                  connectedAt={connection.connectedAt}
                />
              ))}
            </Pane>
          ) : undefined}

          {archivedConnections.length ? (
            <Pane display="flex" flexDirection="column" gap={minorScale(3)}>
              {renderConnectionsHeading("Archived", "neutral", archivedConnections.length)}

              {activeConnections.map((connection) => (
                <ArchivedConnectionCard
                  connectionId={connection.id}
                  recruiter={connection.recruiter}
                  key={connection.id}
                  connectedAt={connection.connectedAt}
                />
              ))}
            </Pane>
          ) : undefined}
        </Pane>
      </Pane>
    );
  };

  const renderRightPanel = () => {
    if (data?.commonApplication.connections?.length) {
      return renderConnections();
    }

    return renderMentors();
  };

  if (loading) {
    return <Loader />;
  } else if (!data?.commonApplication?.answers.length) {
    return <Redirect to="/async-screen" />;
  }

  return (
    <>
      <ResumeNotif />
      <Col style={{ paddingTop: majorScale(5), paddingBottom: majorScale(5) }}>
        {/* Layout uses Container and Row so we need Col as the parent node */}

        <Row>
          <Col xs={12} lg={5} style={{ display: "flex", flexDirection: "column", gap: majorScale(4), marginBottom: majorScale(4) }}>
            <Pane
              padding={majorScale(3)}
              display="flex"
              flexDirection="column"
              gap={majorScale(3)}
              backgroundColor={defaultTheme.colors.white}
              borderRadius={majorScale(1)}
            >
              <Pane display="flex" alignItems="center" gap={majorScale(2)}>
                <Avatar size={majorScale(10)} src={data?.commonApplication.user.picture} />

                <Pane display="flex" flexDirection="column" gap={majorScale(1)}>
                  <Heading>{data?.commonApplication.user.fullName}</Heading>

                  <Pane display="flex" gap={majorScale(2)}>
                    {data?.commonApplication.user.location ? <LocationPane location={data?.commonApplication.user.location} /> : undefined}

                    {renderCurrentRole()}
                  </Pane>
                </Pane>
              </Pane>

              <Pane display="flex" alignItems="center" gap={majorScale(1)}>
                <MobileVideoIcon color={defaultTheme.colors.gray600} />

                <Text>{data?.commonApplication.user.candidate.workStylePreference}</Text>
              </Pane>

              <Pane display="flex" alignItems="center" gap={majorScale(1)}>
                <BriefcaseIcon color={defaultTheme.colors.gray600} />

                <Text>{data?.commonApplication.user.candidate.jobRole}</Text>

                {data?.commonApplication.user.candidate.specialities.map((specialty) => {
                  return (
                    <Pane display="flex" alignItems="center" gap={majorScale(1)} key={specialty.skill}>
                      <Text>&bull;</Text>
                      <Text>{specialty.skill}</Text>
                    </Pane>
                  );
                })}
              </Pane>

              {renderFrameworks()}

              <Pane display="flex" gap={majorScale(2)}>
                <Button is={Link} to={`/p/${data?.commonApplication.user.slug}/edit`} iconBefore={EditIcon}>
                  Edit profile
                </Button>

                {data?.commonApplication.user.candidate.hasResume ? (
                  <Button
                    is="a"
                    href={data?.commonApplication.user.candidate.resume?.url}
                    target="_blank"
                    type="button"
                    iconBefore={DocumentIcon}
                    overflowX="clip"
                  >
                    View resume
                  </Button>
                ) : undefined}
              </Pane>
            </Pane>

            <Pane>
              <Heading marginBottom={majorScale(1)}>Your responses</Heading>

              <Pane display="flex" alignItems="center" gap={minorScale(3)} marginBottom={minorScale(5)}>
                <Switch
                  checked={data?.commonApplication.canShare}
                  onChange={() => setShareCommonApplication({ variables: { canShare: !data?.commonApplication.canShare } })}
                />

                <Text>Share with hiring managers for other relevant roles</Text>
              </Pane>

              <Pane display="flex" flexDirection="column" gap={minorScale(1)}>
                {renderQuestionBlocks()}
              </Pane>
            </Pane>
          </Col>

          <Col xs={12} lg={7} style={{ display: "flex", flexDirection: "column", gap: majorScale(4) }}>
            {renderRightPanel()}
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default withRouter(AsyncScreenDashboardPage);
