import React from "react";
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

import withRoutes from "../withRoutes";
import Layout from "../../components/layout/Layout";

// import LearnerOnboardingPage from './LearnerOnboardingPage.js'
// import CoachOnboardingPage from './CoachOnboardingPage.js'

// import ApplicationOverviewPage from './ApplicationOverviewPage.js'
// import NewApplicationPage from './NewApplicationPage.js'
// import ApplicationStatusPage from './ApplicationStatusPage.js'
// import CoachOnboardingRedirect from './CoachOnboardingRedirect.js'
import CoachApplicationPage from "./CoachApplicationPage";
// import CoachTrainingPage from "./CoachTrainingPage"
// import BookMockSessionPage from "./BookMockSessionPage"
// import ProfileLivePage from "./ProfileLivePage"
// import LeaderSetupPage from "./LeaderSetupPage"
import MentorSetupPage from "./MentorSetupPage";
import CalendarSetupPage from "./CalendarSetupPage";
import WaitlistApplicationPage from "./WaitlistApplicationPage";
import AcceptedApplicationPage from "./AcceptedApplicationPage";

// import AcceptedPage from "./AcceptedPage"

export default withRoutes(Layout, [
  // {
  //   path: "/application/overview",
  //   component: ApplicationOverviewPage,
  //   privateRoute: true,
  // },
  // {
  //   path: "/application/new",
  //   component: NewApplicationPage,
  //   privateRoute: true,
  // },
  // {
  //   path: "/application/accepted",
  //   component: AcceptedPage,
  //   privateRoute: true
  // },
  {
    path: "/application/accepted",
    component: AcceptedApplicationPage,
    privateRoute: true,
    hideNav: true,
    exact: true
  },
  {
    path: "/application/waitlist",
    component: WaitlistApplicationPage,
    privateRoute: true,
    hideNav: true,
    exact: true
  },
  {
    path: "/apply/:step",
    component: CoachApplicationPage,
    privateRoute: true,
    hideNav: true
  },
  // {
  //   path: "/onboarding/coach/:step",
  //   component: CoachOnboardingPage,
  //   privateRoute: true,
  // },
  // {
  //   exact: true,
  //   path: "/onboarding/continue/coach",
  //   component: CoachOnboardingRedirect,
  // },
  {
    path: "/onboarding/coach",
    component: ({ location: { search } }) => <Redirect to={`/onboarding/coach/0${search}`} />
  },
  {
    path: "/mentor/setup/calendar/:step",
    component: CalendarSetupPage,
    privateRoute: true,
    exact: true
  },
  {
    path: "/mentor/setup/calendar",
    component: ({ location: { search } }) => <Redirect to={`/mentor/setup/calendar/0${search}`} />
  },
  // {
  //   path: "/onboarding/learner/:step",
  //   component: LearnerOnboardingPage,
  //   privateRoute: true,
  // },
  {
    path: "/onboarding/learner",
    component: ({ location: { search } }) => <Redirect to={`/onboarding/learner/0${search}`} />
  },
  {
    path: "/training",
    component: ({ location: { search } }) => <Redirect to={`/mentor/setup`} />
  },
  {
    path: "/mentor/setup",
    component: MentorSetupPage,
    privateRoute: true,
    hideNav: true
  }
  // {
  //   path: "/book-mock",
  //   component: BookMockSessionPage,
  //   privateRoute: true,
  //   state: "Training"
  // },
  // {
  //   path: "/profile-live",
  //   component: ProfileLivePage,
  //   ProfileLivePage: true,
  //   state: "Training"
  // },
]);
