import React from "react";
import { withRouter } from "react-router-dom";
import { Form, Formik } from "formik";
import { Pane, Heading, minorScale, majorScale, defaultTheme, RadioGroup, Badge, Checkbox, Button } from "evergreen-ui";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";

import withAuthLayout from "../auth/withAuthLayout";
import CandidateQueries from "../../queries/candidate";
import ProfileList from "../../queries/profile/ProfileList";

const JOB_ROLES = ["Software Engineering", "Product Management", "Product Design", "Other"];

const VISA_STATUSES = [
  { value: "authorized", label: "Authorized and do not need sponsorship" },
  { value: "not_authorized", label: "Not Authorized and will need sponsorship (includes H-1B or similar visas)." }
];

const CHALLENGE_OPTIONS = [
  {
    key: "resume",
    value: "Resume and portfolio review"
  },
  {
    key: "technical_interview",
    value: "Practicing technical interviews"
  },
  {
    key: "finding_roles",
    value: "Finding relevant roles"
  },
  {
    key: "offers",
    value: "Evaluating and negotiating offers"
  }
];

const CandidateSetupJobSearchSchema = Yup.object().shape({
  jobRole: Yup.string().required("Please provide your job role"),
  visaStatus: Yup.string().required("Please provide your visa status")
});

const CandidateSetupJobSearchPage = (props) => {
  const [updateCandidateProfile] = useMutation(CandidateQueries.mutations.UpdateCandidateProfile, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ProfileList.MyProfileQuery }, { query: CandidateQueries.queries.MyCandidateProfile }],
    onCompleted: (results) => {
      if (results?.updateCandidateProfile) {
        let next = "/discover?";
        if (results?.updateCandidateProfile.topicIds?.length > 0) {
          next += `topics=${results?.updateCandidateProfile.topicIds?.join(",")}`;
        }

        if (results?.updateCandidateProfile.jobRole) {
          next += `&roles=${results?.updateCandidateProfile.jobRole}`;
        }

        props.history.push(next);
      }
    }
  });

  return (
    <Formik
      validationSchema={CandidateSetupJobSearchSchema}
      initialValues={{
        jobRole: JOB_ROLES[0],
        visaStatus: VISA_STATUSES[0].value,
        challenges: []
      }}
      onSubmit={(variables, { setSubmitting }) => {
        updateCandidateProfile({ variables });
        setSubmitting(false);
      }}
      component={({ values, errors, isSubmitting, touched, setFieldValue }) => (
        <Form>
          <Pane
            backgroundColor={"white"}
            borderRadius={minorScale(3)}
            border={`1px solid ${defaultTheme.colors.gray200}`}
            padding={minorScale(9)}
            display="flex"
            flexDirection="column"
            gap={minorScale(9)}
          >
            <Pane>
              <Heading size={900}>💼 Tell us more about your job search</Heading>
            </Pane>

            <Pane>
              <Heading size={400} marginBottom={minorScale(1)} color={defaultTheme.colors.gray800}>
                What kind of role are you looking for?
              </Heading>

              <RadioGroup
                defaultValue={values?.jobRole}
                options={JOB_ROLES.map((value) => ({ value, label: value }))}
                onChange={(e) => setFieldValue("jobRole", e.target.value, false)}
              />
              {touched["jobRole"] && errors["jobRole"] && (
                <Badge color="red" marginTop={majorScale(1)} marginBottom={majorScale(1)}>
                  {errors["jobRole"]}
                </Badge>
              )}
            </Pane>

            <Pane>
              <Heading size={400} marginBottom={minorScale(1)} color={defaultTheme.colors.gray800}>
                Are you authorized to work lawfully in the United States?
              </Heading>

              <Heading size={200}>We only collect this information to ensure relevant opportunities are shown.</Heading>

              <RadioGroup
                defaultValue={values?.visaStatus}
                options={VISA_STATUSES}
                onChange={(e) => setFieldValue("visaStatus", e.target.value, false)}
              />
              {touched["visaStatus"] && errors["visaStatus"] && (
                <Badge color="red" marginTop={majorScale(1)} marginBottom={majorScale(1)}>
                  {errors["visaStatus"]}
                </Badge>
              )}
            </Pane>

            <Pane>
              <Heading size={400} marginBottom={minorScale(1)} color={defaultTheme.colors.gray800}>
                What’s been your biggest challenge while finding a role?
              </Heading>

              <Heading size={200}>
                We’ll use this information to help recommend mentors and topics on the next page. Select as many as you’d like.
              </Heading>

              <Pane>
                {CHALLENGE_OPTIONS.map((option, index) => (
                  <Checkbox
                    key={option.key}
                    label={option.value}
                    name={`challenges.${index}`}
                    value={option.key}
                    checked={values.challenges.includes(option.key)}
                    onChange={(e) => {
                      console.log(e.target.checked, option.key);
                      if (e.target.checked) {
                        setFieldValue("challenges", [...values.challenges, option.key]);
                      } else {
                        setFieldValue(
                          "challenges",
                          values.challenges.filter((challenge) => challenge !== option.key)
                        );
                      }
                    }}
                  />
                ))}
              </Pane>
            </Pane>

            <Pane display="flex" justifyContent="flex-end">
              <Button
                width={["xs", "sm", "md"].includes(props.screenClass) ? "100%" : undefined}
                appearance="primary"
                type="submit"
                disabled={isSubmitting}
              >
                Go to your profile
              </Button>
            </Pane>
          </Pane>
        </Form>
      )}
    />
  );
};

export default withRouter(withAuthLayout(CandidateSetupJobSearchPage));
