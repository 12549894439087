import _ from "lodash";
import React from "react";
import { useMutation } from "@apollo/client";
import { Badge, Button, Heading, Pane, majorScale } from "evergreen-ui";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Select from "react-select";

import ApplicationQueries from "../../queries/application";
import ProfileList from "../../queries/profile/ProfileList";

const ROLE_PLACEHOLDER = `Please choose your job role`;
const LEVEL_PLACEHOLDER = `Please choose your job level`;
const MOTIVATION_PLACEHOLDER = `Please choose option(s) that best fit`;

const CoachCareerSchema = Yup.object().shape({
  jobRoles: Yup.array().of(Yup.string()).required("Please include the job roles that best describe you"),
  level: Yup.string().required("Please include your highest job level attained"),
  motivations: Yup.array().of(Yup.string()).required("Please include at least one motivation for mentoring")
});

const createLabel = (label) => ({ value: label, label });

const errorText = (text) => <Badge color="red">{text}</Badge>;

const CoachCareerForm = (props) => {
  const [coachCareerUpdate, { loading }] = useMutation(ApplicationQueries.mutations.ExpandApplication, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: ApplicationQueries.queries.MyProfile },
      { query: ProfileList.MyCoachingProfile },
      { query: ProfileList.MyProfileQuery }
    ],
    onCompleted: (result) => {
      window.analytics.track("Coach Application Career");

      props.handleNextStep(result);
    }
  });

  let { availableRoles, availableLevels, availableMotivations } = props;

  availableRoles = availableRoles.map(createLabel);
  availableLevels = availableLevels.map(createLabel);
  availableMotivations = availableMotivations.map(createLabel);

  return (
    <Formik
      validationSchema={CoachCareerSchema}
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={{
        level: null,
        jobRoles: [],
        motivations: []
      }}
      onSubmit={(variables, { setSubmitting }) => {
        coachCareerUpdate({ variables });
        setSubmitting(false);
      }}
      component={({ values, errors, setFieldValue }) => (
        <Form>
          <Pane display="flex" flexDirection="column">
            {/* Current Role */}
            <Heading size={600} paddingBottom={majorScale(1)} paddingTop={majorScale(2)}>
              What is your current role?
            </Heading>
            <Select
              options={availableRoles}
              defaultValue={values.jobRoles}
              isClearable={true}
              placeholder={ROLE_PLACEHOLDER}
              onChange={(selected) => {
                if (selected?.value) {
                  setFieldValue("jobRoles", [selected.value]);
                }
              }}
            />
            {errors.jobRoles ? errorText(errors.jobRoles) : null}
            {/* Current Level */}
            <Heading size={600} paddingBottom={majorScale(1)} paddingTop={majorScale(2)}>
              What is your current level in that role?
            </Heading>
            <Select
              options={availableLevels}
              defaultValue={values.level}
              isClearable={true}
              placeholder={LEVEL_PLACEHOLDER}
              onChange={(selected) => {
                if (selected && selected.value) {
                  setFieldValue("level", selected.value);
                }
              }}
            />
            {errors.level ? errorText(errors.level) : null}

            {/* Motivations */}
            <Heading size={600} paddingBottom={majorScale(1)} paddingTop={majorScale(2)}>
              What do you want to get out of Merit?
            </Heading>

            <Pane paddingBottom={majorScale(2)}>
              <Select
                isMulti
                options={availableMotivations}
                defaultValue={values.motivations}
                placeholder={MOTIVATION_PLACEHOLDER}
                onChange={(selected) => {
                  const motivations = _.map(selected, (motivation) => motivation.value);
                  setFieldValue("motivations", motivations);
                }}
              />
              {errors.motivations ? errorText(errors.motivations) : null}
            </Pane>

            {/* Form Submission */}
            <Pane display="flex" justifyContent="flex-end" paddingTop={majorScale(2)}>
              <Button appearance="primary" type="submit" isLoading={loading}>
                {props.lastStep ? "Submit Application" : "Next Step"}
              </Button>
            </Pane>
          </Pane>
        </Form>
      )}
    />
  );
};

export default CoachCareerForm;
