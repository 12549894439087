import { Pane, Heading, majorScale, defaultTheme, Text, Strong, Paragraph, Button, minorScale } from "evergreen-ui";
import React, { useMemo } from "react";
import { Row, Col } from "react-grid-system";
import { useQuery } from "@apollo/client";
import { Link, withRouter } from "react-router-dom";
import { AsyncScreenQueries } from "../../queries/async-screen";

import randy from "../../images/randy-with-bricks.png";
import step1Icon from "../../images/step-1-async-screen-icon.png";
import step1Hero from "../../images/step-1-async-screen-hero.png";
import step2Icon from "../../images/step-2-async-screen-icon.png";
import step2Hero from "../../images/step-2-async-screen-hero.png";
import step3Icon from "../../images/step-3-async-screen-icon.png";
import step3Hero from "../../images/step-3-async-screen-hero.png";
import AsyncScreenJob from "../../components/async-screen/AsyncScreenJob";

const AsyncScreenLandingPage = (props) => {
  const { loading, data } = useQuery(AsyncScreenQueries.queries.GetQuestions);
  const queryParams = new URLSearchParams(props.location.search);

  const nextStep = useMemo(() => {
    if (!loading) {
      return data.questions[0];
    }
    return {};
  }, [loading, data]);

  const renderStep = (stepHero, stepIcon, heading, paragraph) => {
    return (
      <Col xs={12} xl={4}>
        <Pane display="flex" flexDirection="column" gap={majorScale(1)}>
          <img src={stepHero} />

          <Pane display="flex" alignItems="center" gap={majorScale(1)}>
            <img src={stepIcon} width={32} height={32} />

            <Heading size={500}>{heading}</Heading>
          </Pane>

          <Paragraph color={defaultTheme.colors.dark} size={500}>
            {paragraph}
          </Paragraph>
        </Pane>
      </Col>
    );
  };

  return (
    <Col style={{ paddingTop: majorScale(5), paddingBottom: majorScale(5) }}>
      {/* Layout uses Container and Row so we need Col as the parent node */}

      {queryParams.get("jobId") ? (
        <Row style={{ marginBottom: `${majorScale(4)}px` }}>
          <Col xs={3} md={2}>
            <Pane display="flex" flexDirection="column">
              <img src={randy} style={{ marginBottom: `${minorScale(3)}px` }} />

              <Strong color={defaultTheme.colors.gray900}>RANDY</Strong>

              <Text>Founder at Merit</Text>
            </Pane>
          </Col>

          <Col xs={9} md={10}>
            <Pane
              position="relative"
              display="flex"
              flexDirection="column"
              gap={majorScale(3)}
              padding={majorScale(3)}
              borderRadius={majorScale(2)}
              backgroundColor={defaultTheme.colors.white}
            >
              <Pane position="absolute" left={-24} top={50}>
                <svg height="32" width="24">
                  <polygon points="24,0 0,16 24,32" fill={defaultTheme.colors.white} />
                </svg>
              </Pane>

              <Heading size={700} color={defaultTheme.colors.dark}>
                I think you might be a fit for this role!
              </Heading>

              <AsyncScreenJob />
            </Pane>
          </Col>
        </Row>
      ) : undefined}

      <Row justify="end">
        <Col xs={12} md={10} xl={12}>
          <Pane
            display="flex"
            flexDirection="column"
            gap={majorScale(3)}
            padding={majorScale(3)}
            borderRadius={majorScale(2)}
            backgroundColor={defaultTheme.colors.white}
          >
            <Heading size={700} color={defaultTheme.colors.dark}>
              Can you answer a few questions to confirm? Here's how it works...
            </Heading>

            <Row>
              {renderStep(
                step1Hero,
                step1Icon,
                "Answer 3 common questions",
                "Record short voice memos in response to industry-wide introductory questions. You can complete your application at any time."
              )}

              {renderStep(
                step2Hero,
                step2Icon,
                "We hear your responses",
                "The Merit team listens to your responses, then shares highlights with companies where you might be a fit."
              )}

              {renderStep(
                step3Hero,
                step3Icon,
                "We intro you if it’s a match",
                "The Merit team gives you a warm referral directly to the hiring manager, speeding up the process of getting you that new job."
              )}
            </Row>

            <Pane
              display="flex"
              justifyContent="flex-end"
              paddingTop={majorScale(4)}
              style={{ borderTop: `1px solid ${defaultTheme.colors.gray400}` }}
            >
              <Link to={`/async-screen/${nextStep.title}`}>
                <Button appearance="primary">Get started</Button>
              </Link>
            </Pane>
          </Pane>
        </Col>
      </Row>
    </Col>
  );
};

export default withRouter(AsyncScreenLandingPage);
