import React from "react";
import { majorScale, Pane, defaultTheme, Text, Button, Paragraph, SmallTickIcon, CrossIcon } from "evergreen-ui";
import { useMutation } from "@apollo/client";
import { AsyncScreenQueries } from "../../queries/async-screen";
import ConnectionCardHeader from "./ConnectionCardHeader";
import JobLink from "./JobLink";

const PendingConnectionCard = (props) => {
  const mutationOptions = {
    variables: {
      connectionId: props.connectionId
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: AsyncScreenQueries.queries.GetDashboard,
        variables: {
          cursor: "1"
        }
      }
    ]
  };
  const [acceptCommonApplicationConnection] = useMutation(AsyncScreenQueries.mutations.AcceptCommonApplicationConnection, mutationOptions);
  const [archiveCommonApplicationConnection] = useMutation(
    AsyncScreenQueries.mutations.ArchiveCommonApplicationConnection,
    mutationOptions
  );

  return (
    <Pane
      padding={majorScale(3)}
      display="flex"
      flexDirection="column"
      gap={majorScale(3)}
      backgroundColor={defaultTheme.colors.white}
      borderRadius={majorScale(1)}
      border={`1px solid ${defaultTheme.colors.gray500}`}
    >
      <ConnectionCardHeader recruiter={props.recruiter} />

      <Pane
        backgroundColor={defaultTheme.colors.gray90}
        display="flex"
        flexDirection="column"
        gap={majorScale(2)}
        borderRadius={majorScale(1)}
        padding={majorScale(3)}
      >
        <Paragraph>{props.message}</Paragraph>

        <JobLink job={props.job} />

        <Text>
          {`SENT ${new Date(parseInt(props.sentAt, 10)).toLocaleDateString("en-US", { month: "long", day: "numeric", year: "numeric" })}`.toUpperCase()}
        </Text>
      </Pane>

      <Pane display="flex" justifyContent="space-between" alignItems="center">
        <Text size={300}>If you accept, we’ll connect you over email.</Text>

        <Pane display="flex" gap={majorScale(1)}>
          <Button onClick={archiveCommonApplicationConnection} iconBefore={<CrossIcon color={defaultTheme.colors.gray600} />}>
            Ignore
          </Button>

          <Button
            appearance="primary"
            iconBefore={<SmallTickIcon color={defaultTheme.colors.white} />}
            onClick={acceptCommonApplicationConnection}
          >
            Accept
          </Button>
        </Pane>
      </Pane>
    </Pane>
  );
};

export default PendingConnectionCard;
