import React from "react";
import { Button, Pane, Paragraph, majorScale } from "evergreen-ui";
import { withRouter } from "react-router-dom";

const EmailSignup = (props) => {
  return (
    <Pane display="flex" flexDirection="column" alignItems="center">
      {props.subtitle ? <Paragraph marginBottom={majorScale(2)}>{props.subtitle}</Paragraph> : null}
      <Pane display="flex" flexDirection="row">
        <Button
          appearance="primary"
          type="submit"
          height={40}
          minWidth={125}
          borderRadius={0}
          textAlign="center"
          onClick={() => props.history.push("/signup")}
        >
          {props.cta}
        </Button>
      </Pane>
    </Pane>
  );
};

export default withRouter(EmailSignup);
