import { useQuery } from "@apollo/client";
import { Pane, Heading, majorScale, defaultTheme, Paragraph, Button, minorScale, UnorderedList, ListItem, Spinner } from "evergreen-ui";
import React, { useState } from "react";
import { Col } from "react-grid-system";
import qs from "query-string";
import { withRouter, Link } from "react-router-dom";
import Steps from "../../components/async-screen/Steps";
import { AsyncScreenQueries } from "../../queries/async-screen";
import Recorder from "../../components/async-screen/Recorder";
import { withAnswerCreation } from "../../components/async-screen/withAnswerCreation";

const AsyncScreenQuestionLayout = (props) => {
  const { loading, data } = useQuery(AsyncScreenQueries.queries.GetAsyncScreenInfo, {
    variables: {
      title: `${props.match.params.step}.webm`
    }
  });
  const [saved, setSaved] = useState(false);
  const [audio, setAudio] = useState();
  const [savedDuration, setSavedDuration] = useState(props.duration || 0);
  const searchParams = qs.parse(props.location.search, { ignoreQueryPrefix: true });

  if (loading) {
    return <Spinner />;
  }

  const steps = [...data.questions.map((question) => question.title), "Review"];
  const currentStepIndex = data.questions.findIndex((question) => question.title === props.match.params.step);
  const currentStep = data.questions[currentStepIndex];
  const nextStep = steps[currentStepIndex + 1];

  const onSave = (audio, savedDuration) => {
    props
      .uploadAnswer(data.answerUploadPresignedUrl, currentStep.id)(audio, savedDuration)
      .finally(() => setSaved(true));
  };

  return (
    <Col style={{ paddingTop: majorScale(5), paddingBottom: majorScale(5), display: "flex", flexDirection: "column", gap: majorScale(4) }}>
      {/* Layout uses Container and Row so we need Col as the parent node */}

      {searchParams.isRerecord ? undefined : <Steps steps={steps} currentStepIndex={currentStepIndex} />}

      <Pane borderRadius={12} backgroundColor={defaultTheme.colors.gray200}>
        <Pane display="flex" flexDirection="row">
          <Pane backgroundColor={defaultTheme.colors.gray600} padding={majorScale(3)} borderTopLeftRadius={minorScale(3)}>
            <Heading color={defaultTheme.colors.white}>{currentStepIndex + 1}</Heading>
          </Pane>

          <Pane backgroundColor={defaultTheme.colors.white} padding={majorScale(3)} borderTopRightRadius={minorScale(3)} width="100%">
            <Heading>{currentStep.text}</Heading>
          </Pane>
        </Pane>

        <Pane padding={majorScale(3)} gap={majorScale(3)} display="flex" flexDirection="column">
          <Pane>
            <Heading size={300} color={defaultTheme.colors.gray900} marginBottom={majorScale(1)}>
              PROMPTS
            </Heading>

            <Pane>
              <UnorderedList>
                {currentStep.prompts.map((prompt) => {
                  return <ListItem key={prompt}>{prompt}</ListItem>;
                })}
              </UnorderedList>

              <Paragraph>{currentStep.additionalInfo}</Paragraph>
            </Pane>
          </Pane>

          <Pane>
            <Heading size={300} color={defaultTheme.colors.gray900} marginBottom={majorScale(1)}>
              RESPONSE
            </Heading>

            <Paragraph>
              Record a voice memo to respond to the question. Your response will only be used to determine if there’s a match.
            </Paragraph>
          </Pane>

          <Recorder audio={audio} setAudio={setAudio} savedDuration={savedDuration} setSavedDuration={setSavedDuration} onSave={onSave} />
        </Pane>
      </Pane>

      <Pane justifyContent="flex-end" display="flex">
        <Button
          is={Link}
          to={searchParams.isRerecord ? `/async-screen/dashboard` : `/async-screen/${nextStep}`}
          appearance="primary"
          isLoading={props.isUploading}
          disabled={!saved}
          onClick={() => {
            setSaved(false);
            setAudio(undefined);
            setSavedDuration(0);
          }}
        >
          {searchParams.isRerecord ? `Publish` : `Continue`}
        </Button>
      </Pane>
    </Col>
  );
};

export default withAnswerCreation(withRouter(AsyncScreenQuestionLayout));
