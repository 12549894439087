import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpRightFromSquare,
  faMagnifyingGlassLocation,
  faMessage,
  faUserXmark,
  faGraduationCap,
  faComments
} from "@fortawesome/free-solid-svg-icons";
import { useMutation } from "@apollo/client";
import { withRouter } from "react-router-dom";
import { Badge, Button, Heading, Pane, majorScale, minorScale, defaultTheme, Group } from "evergreen-ui";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";

import CandidateQueries from "../../queries/candidate";
import ProfileList from "../../queries/profile/ProfileList";
import TextInput from "../../components/form/TextInput";
import LocationAutocomplete from "../../components/LocationAutocomplete";
import withAuthLayout from "../auth/withAuthLayout";
import RadioButton from "./RadioButton";
import CheckboxButton from "./CheckboxButton";

const CreateCandidateProfileSchema = Yup.object().shape({
  linkedin: Yup.string().url("Please provide a valid URL."),
  place: Yup.object({
    formatted: Yup.string()
  }).test("Place", "Please provide your location.", (value) => {
    return Object.keys(value).length > 0;
  }),
  mode: Yup.object({
    learn: Yup.bool(),
    coach: Yup.bool()
  }).test("Mode", "Please choose one.", (value) => {
    return value.learn || value.coach;
  })
});

const use = [
  {
    label: "Learn from a mentor",
    icon: faGraduationCap,
    value: "learn" // TODO: this should be an enum
  },
  {
    label: "Mentor others in tech",
    icon: faComments,
    value: "coach" // TODO: this should be an enum
  }
];

const searchStatus = [
  {
    icon: faMagnifyingGlassLocation,
    label: "Actively searching",
    value: "active" // TODO: this should be an enum
  },
  {
    icon: faMessage,
    label: "Open to new roles",
    value: "passive" // TODO: this should be an enum
  },
  {
    icon: faUserXmark,
    label: "Not looking",
    value: "inactive" // TODO: this should be an enum
  }
];

const CandidateSetupPage = ({ history, ...props }) => {
  const [createCandidateProfile] = useMutation(CandidateQueries.mutations.CreateCandidateProfile, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ProfileList.MyProfileQuery }, { query: CandidateQueries.queries.MyCandidateProfile }],
    onCompleted: (results, opts) => {
      if (results?.createCandidateProfile) {
        if (results?.createCandidateProfile.applicationStatus?.includes("Waitlist")) {
          history.push("/waitlist");
        } else if (["Coach", "Both"].includes(opts.variables?.mode)) {
          history.push("/apply/0");
        } else if (["active", "passive"].includes(results?.createCandidateProfile.status)) {
          history.push("/candidate/setup/job-search");
        } else {
          history.push("/discover?hello=true&question=true");
        }
      }
    }
  });

  const isSmallWidth = ["xs", "sm"].includes(props.screenClass);
  const selectionButtonHeight = isSmallWidth ? majorScale(7) : majorScale(10);

  return (
    <Formik
      initialValues={{
        place: {},
        linkedin: "",
        mode: {
          learn: false,
          coach: false
        },
        jobSearchMode: "active"
      }}
      validationSchema={CreateCandidateProfileSchema}
      onSubmit={(variables, { setSubmitting }) => {
        let mode = "Learn"; // TODO: this should be an enum
        if (variables.mode.learn && variables.mode.coach) {
          mode = "Both"; // TODO: this should be an enum
        } else if (variables.mode.coach) {
          mode = "Coach"; // TODO: this should be an enum
        }

        createCandidateProfile({
          variables: {
            ...variables,
            mode
          }
        });
        setSubmitting(false);
      }}
      component={({ values, errors, touched, isSubmitting, setFieldValue }) => (
        <Form>
          <Pane
            backgroundColor={"white"}
            borderRadius={minorScale(3)}
            border={`1px solid ${defaultTheme.colors.gray200}`}
            padding={minorScale(9)}
            display="flex"
            flexDirection="column"
            gap={minorScale(9)}
          >
            <Pane paddingBottom={minorScale(9)} borderBottom={`1px solid ${defaultTheme.colors.gray200}`}>
              <Heading size={900} marginBottom={minorScale(3)}>
                Happy you're here.
              </Heading>

              <Heading size={400} color={defaultTheme.colors.gray700}>
                Tell us a bit more about you so we can help you achieve your goals.
              </Heading>
            </Pane>

            <Pane>
              <Pane>
                <Heading size={400} marginBottom={minorScale(1)}>
                  What is your LinkedIn?
                </Heading>

                <Heading size={200}>
                  Optional. Save time by automatically filling in your Merit profile with public information from LinkedIn.
                  <Button
                    color={defaultTheme.colors.blue400}
                    fontSize="12px"
                    fontWeight="600"
                    marginBottom="2px"
                    padding="0"
                    is="a"
                    href="https://www.linkedin.com/in/"
                    target="_blank"
                    appearance="minimal"
                    iconAfter={<FontAwesomeIcon icon={faArrowUpRightFromSquare} />}
                  >
                    Find your LinkedIn URL
                  </Button>
                </Heading>
              </Pane>

              <Field
                type="text"
                name="linkedin"
                placeholder="https://www.linkedin.com/in/your-handle-here"
                component={TextInput}
                height={majorScale(5)}
                onBlur={(e) => {
                  e.persist();
                  setFieldValue("linkedin", e.target.value, false);
                }}
              />
            </Pane>

            <Pane>
              <Heading size={400} marginBottom={minorScale(1)}>
                Where are you located?
              </Heading>

              <Heading size={200} marginBottom={minorScale(2)}>
                Merit works best for those working in the US or Canada.
              </Heading>

              <LocationAutocomplete
                myProfile={values}
                title="Enter a location"
                onSelected={(chosen) => setFieldValue("place", chosen, false)}
              />

              {touched["place"] && errors["place"] && (
                <Badge color="red" marginTop={majorScale(1)} marginBottom={majorScale(1)}>
                  {errors["place"]}
                </Badge>
              )}
            </Pane>

            <Pane>
              <Heading size={400} marginBottom={minorScale(1)}>
                How do you want to use Merit?
              </Heading>

              <Heading size={200} marginBottom={minorScale(2)}>
                Select all that apply. If you’re looking to mentor others, we’ll follow up with more information later.
              </Heading>

              <Pane display="flex" flexDirection={isSmallWidth ? "column" : "row"} gap={minorScale(4)}>
                {use.map(({ label, icon, value }) => (
                  <CheckboxButton
                    key={label}
                    label={label}
                    icon={icon}
                    isActive={values.mode[value]}
                    onClick={(e) => {
                      e.preventDefault();
                      setFieldValue("mode", { ...values.mode, [value]: !values.mode[value] });
                    }}
                    height={selectionButtonHeight}
                  />
                ))}
              </Pane>

              {touched["mode"] && errors["mode"] && (
                <Badge color="red" marginTop={majorScale(1)} marginBottom={majorScale(1)}>
                  {errors["mode"]}
                </Badge>
              )}
            </Pane>

            <Pane>
              <Heading size={400} marginBottom={minorScale(1)}>
                Are you currently looking for a job?
              </Heading>

              <Heading size={200} marginBottom={minorScale(2)}>
                We can add you to our talent directory and connect you with jobs. If you’re actively looking, we’ll ask a few more
                questions.
              </Heading>

              <Group display="flex" flexDirection={isSmallWidth ? "column" : "row"}>
                {searchStatus.map(({ icon, label, value }) => (
                  <RadioButton
                    icon={icon}
                    key={label}
                    label={label}
                    isActive={values.jobSearchMode === value}
                    onClick={() => setFieldValue("jobSearchMode", value)}
                    alignItems={isSmallWidth ? "center" : "flex-start"}
                    height={selectionButtonHeight}
                  />
                ))}
              </Group>
            </Pane>

            <Pane display="flex" justifyContent="flex-end">
              <Button
                width={["xs", "sm", "md"].includes(props.screenClass) ? "100%" : undefined}
                appearance="primary"
                type="submit"
                disabled={isSubmitting}
              >
                Continue
              </Button>
            </Pane>
          </Pane>
        </Form>
      )}
    />
  );
};

export default withRouter(withAuthLayout(CandidateSetupPage));
