import { withRouter } from "react-router-dom";
import { useQuery } from "@apollo/client";
import {
  Pane,
  Spinner,
  defaultTheme,
  majorScale,
  DocumentShareIcon,
  Heading,
  Text,
  minorScale,
  LocateIcon,
  PeopleIcon,
  DollarIcon
} from "evergreen-ui";
import { AsyncScreenQueries } from "../../queries/async-screen";

const AsyncScreenJob = (props) => {
  const queryParams = new URLSearchParams(props.location.search);
  const { loading, data } = useQuery(AsyncScreenQueries.queries.GetJob, {
    variables: {
      jobId: queryParams.get("jobId")
    }
  });

  if (loading) {
    return <Spinner />;
  }

  return (
    <a href={data.jobById.link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
      <Pane
        backgroundColor={defaultTheme.colors.gray300}
        padding={majorScale(3)}
        borderRadius={majorScale(1)}
        display="flex"
        justifyContent="space-between"
      >
        <Pane display="flex" flexDirection="column" gap={majorScale(2)}>
          <Pane display="flex" gap={minorScale(3)}>
            <img src={data.jobById.team.logo} width={majorScale(6)} height={majorScale(6)} style={{ borderRadius: minorScale(1) }} />

            <Pane display="flex" flexDirection="column" justifyContent="space-evenly">
              <Heading color={defaultTheme.colors.dark}>{data.jobById.title}</Heading>

              <Text color={defaultTheme.colors.dark}>{data.jobById.team.name}</Text>
            </Pane>
          </Pane>

          <Pane display="flex" gap={majorScale(2)}>
            <Pane display="flex" alignItems="center" gap={minorScale(1)}>
              <LocateIcon color={defaultTheme.colors.gray600} />

              <Text size={300} color={defaultTheme.colors.default}>
                {data.jobById.location}
              </Text>
            </Pane>

            {data.jobById.team.teamSize ? (
              <Pane display="flex" alignItems="center" gap={minorScale(1)}>
                <PeopleIcon color={defaultTheme.colors.gray600} />

                <Text size={300} color={defaultTheme.colors.default}>
                  {data.jobById.team.teamSize}
                </Text>
              </Pane>
            ) : undefined}

            {data.jobById.salary ? (
              <Pane display="flex" alignItems="center" gap={minorScale(1)}>
                <DollarIcon color={defaultTheme.colors.gray600} />

                <Text size={300} color={defaultTheme.colors.default}>
                  {data.jobById.salary}
                </Text>
              </Pane>
            ) : undefined}
          </Pane>
        </Pane>

        <Pane display="flex">
          <DocumentShareIcon color={defaultTheme.colors.gray600} />
        </Pane>
      </Pane>
    </a>
  );
};

export default withRouter(AsyncScreenJob);
