import withRoutes from "../withRoutes";
import AsyncScreenLandingPage from "./AsyncScreenLandingPage";
import Layout from "../../components/layout/Layout";
import AsyncScreenQuestionLayout from "./AsyncScreenQuestionLayout";
import AsyncScreenReviewPage from "./AsyncScreenReviewPage";
import AsyncScreenDashboardPage from "./AsyncScreenDashboardPage";

export default withRoutes(Layout, [
  {
    path: "/async-screen/dashboard",
    exact: true,
    component: AsyncScreenDashboardPage
  },
  {
    path: "/async-screen/Review",
    exact: true,
    component: AsyncScreenReviewPage
  },
  {
    path: "/async-screen/:step",
    component: AsyncScreenQuestionLayout,
    privateRoute: true
  },
  {
    path: "/async-screen",
    exact: true,
    component: AsyncScreenLandingPage
  }
]);
