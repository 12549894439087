import gql from "graphql-tag";

import ProfileList from "../profile/ProfileList";

const AuthQueries = {
  fragments: {},
  queries: {},
  mutations: {}
};

AuthQueries.mutations.ResetPassword = gql`
  mutation resetPassword($resetToken: String!, $password: String!) {
    resetPassword(resetToken: $resetToken, password: $password) {
      jwt
      id
      name {
        first
        last
      }
      linkedin
      bio
      schedule
      role {
        title
        team {
          name
        }
      }
    }
  }
`;

AuthQueries.mutations.RequestResetPassword = gql`
  mutation requestResetPassword($email: String!) {
    requestResetPassword(email: $email)
  }
`;

AuthQueries.mutations.CreateAccount = gql`
  mutation createAccount($email: String!, $firstName: String!, $lastName: String!, $password: String!) {
    createAccount(email: $email, firstName: $firstName, lastName: $lastName, password: $password)
  }
`;

AuthQueries.mutations.CreateAccountPassword = gql`
  mutation createAccountPassword($resetToken: String!, $password: String!) {
    createAccountPassword(resetToken: $resetToken, password: $password) {
      jwt,
      ${ProfileList.profileFragment}
    }
  }
`;

AuthQueries.mutations.RegisterAccount = gql`
  mutation RegisterAccount($email: String!, $name: String!, $linkedin: String!, $attribution: String, $location: String) {
    registerAccount(email: $email, name: $name, linkedin: $linkedin, attribution: $attribution, location: $location)
  }
`;

AuthQueries.mutations.CreatePassword = gql`
  mutation createPassword(
    $name: String!,
    $mode: String!,
    $password: String!,
    $groupId: String,
    $jobSearchMode: String,
  ) {
    createPassword(password: $password, name: $name, mode: $mode, groupId: $groupId, jobSearchMode: $jobSearchMode) {
      ${ProfileList.profileFragment},
      applicationStatus,
      jwt,
      jobSearchMode,
    }
  }
`;

AuthQueries.mutations.CreateAccountFromGoogle = gql`
  mutation createAccountFromGoogle($mode: String!, $jobSearchMode: String) {
    createAccountFromGoogle(mode: $mode, jobSearchMode: $jobSearchMode) {
      ${ProfileList.profileFragment},
      applicationStatus,
      jwt,
      jobSearchMode,
    }
  }
`;

AuthQueries.mutations.MobileSignup = gql`
  mutation mobileSignup($name: String!, $email: String!, $attribution: String, $jobSearchMode: String) {
    mobileSignup(name: $name, email: $email, attribution: $attribution, jobSearchMode: $jobSearchMode)
  }
`;

AuthQueries.mutations.MagicLogin = gql`
  mutation MagicLogin($token: String) {
    magicLogin(token: $token)
  }
`;

export default AuthQueries;
