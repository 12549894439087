import React from "react";
import { Pane, Text, defaultTheme, majorScale, Button, ArchiveIcon } from "evergreen-ui";
import ConnectionCardHeader from "./ConnectionCardHeader";
import JobLink from "./JobLink";
import { useMutation } from "@apollo/client";
import { AsyncScreenQueries } from "../../queries/async-screen";

const ActiveConnectionCard = (props) => {
  const [archiveCommonApplicationConnection] = useMutation(AsyncScreenQueries.mutations.ArchiveCommonApplicationConnection, {
    variables: {
      connectionId: props.connectionId
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: AsyncScreenQueries.queries.GetDashboard,
        variables: {
          cursor: "1"
        }
      }
    ]
  });

  return (
    <Pane
      padding={majorScale(3)}
      display="flex"
      flexDirection="column"
      gap={majorScale(3)}
      backgroundColor={defaultTheme.colors.white}
      borderRadius={majorScale(1)}
      border={`1px solid ${defaultTheme.colors.gray500}`}
    >
      <ConnectionCardHeader recruiter={props.recruiter} />

      <JobLink job={props.job} />

      <Pane display="flex" justifyContent="space-between" alignItems="center">
        <Text size={300}>
          {`CONNECTED ${new Date(parseInt(props.connectedAt, 10)).toLocaleDateString("en-US", { month: "long", day: "numeric", year: "numeric" })}`.toUpperCase()}
        </Text>

        <Button onClick={archiveCommonApplicationConnection} iconBefore={<ArchiveIcon color={defaultTheme.colors.gray600} />}>
          Archive
        </Button>
      </Pane>
    </Pane>
  );
};

export default ActiveConnectionCard;
